export const TRANSLATIONS_ES_ES = {
  menu: {
    menu1: 'Qué Hacemos',
    menu2: 'Cómo Funciona',
    menu3: 'Sé un Afiliado',
    menu4: 'Sé un Socio',
    menu5: 'Preguntas',
    menu6: 'Blog',
    button_cadastre_agora: 'Regístrate',
    button_entrar: 'Iniciar Sesión',
  },
  home: {
    title: 'Todo en Un Solo Lugar',
    subTitle: 'Tu plataforma de intercambio en Canadá con un programa de recompensas',
    button_o_que_fazemos: 'Qué Hacemos',
    button_cadastre_agora: 'Regístrate Ahora',
    title2: 'Estudia donde quieras en Canadá',
    brand1: 'Tu',
    brand2: 'Marca',
    brand3: 'AQUÍ',
    title3_1: 'Somos la solución completa',
    title3_2: ': cursos, visas, alojamiento,',
    title3_3: 'traducciones certificadas, seguro de salud y mucho más.',
    subtitle3: 'Aquí encuentras todo en línea para organizar tu viaje de estudio en Canadá.',
    subtitle3_1: 'Al inscribirte a través de la plataforma y acceder a otros servicios, ganas puntos de fidelidad.',
    subtitle3_2: 'Cada punto se devuelve como cashback.',
    title4: 'Estudiante',
    subtitle4: 'Tenemos alianzas con instituciones educativas en todo Canadá. Ofrecemos una experiencia completa para que organices tu intercambio.',
    button_pesquise_outros_servicos: 'Busca otros servicios',
    button_pesquise_onde_estudar: 'Busca donde estudiar',
    title5: 'Cashback Real',
    subtitle5: 'Además de no cobrar ninguna tarifa, también ganas puntos que se convierten en cashback. Cada matrícula y servicio contratado con nosotros genera puntos que hacen que tu intercambio sea asequible.',
    title6: 'Sé un Afiliado',
    subtitle6_1: '¿Te gusta nuestra solución y quieres ganar un ingreso extra? ',
    subtitle6_2: 'Refiere estudiantes a nuestra plataforma. ',
    subtitle6_3: 'Cuanto más estudiantes refieras que se conviertan en estudiantes en Canadá, ',
    subtitle6_4: 'más ganarás.',
    button_entenda_como: 'Entiende Cómo',
    title7: 'Sé un Socio',
    subtitle7_1: '¿Qué te parece que tu escuela o empresa sea parte de Next',
    subtitle7_2: ' Intake? Regístrate en nuestra plataforma y atrae a muchos más ',
    subtitle7_3: ' estudiantes y clientes.',
    title8: 'Testimonios',
    depoimento1: 'Mi esposa y yo estamos agradecidos por haber contado con la ayuda de Canadá Sin Fronteras para asistirnos con nuestro “plan Canadá”. Todo el apoyo y la transparencia fueron cruciales para nuestra mudanza a Vancouver en solo 9 meses. Si estás pensando en mudarte a Canadá, te recomiendo encarecidamente que tomes esta iniciativa ahora con Canadá Sin Fronteras. ¡Muchas gracias, Emerson y Tati!',
    depoimento1_location: 'Vancouver, Canadá',
    depoimento2: 'He sido cliente de Canadá Sin Fronteras desde 2021 y estoy muy satisfecha con la calidad de los servicios que ofrecen. Mi experiencia con ellos ha sido simplemente excelente. Contraté varios servicios, incluyendo la obtención de mi visa de estudiante (en 2021) y la búsqueda de la universidad ideal para el curso que deseaba estudiar (en 2022). Durante todo el proceso, Tati siempre proporcionó toda la información detallada y explicaciones...',
    depoimento2_location: 'Vancouver, Canadá',
    depoimento3: 'No había encontrado aún personas verdaderas para hablar de un proyecto de sueños. Encontré a Canadá Sin Fronteras por casualidad y fue la mejor elección que hice. La única empresa que valoró mi objetivo en todo momento. Alcanzamos mi objetivo juntos, y estoy inmensamente agradecido con Emerson, Tati, Jaqueline, William y otras personas que sé que trabajan duro. Todos son extremadamente atentos, transparentes y de gran...',
    depoimento3_location: 'Vancouver, Canadá',
    depoimento4: 'El servicio de todo el equipo (especialmente Emerson y Tati) es excelente. Son muy atentos y siempre responden prontamente. Auxiliaron a mi familia en todas las etapas del proceso (lugar ideal para vivir según nuestros objetivos, visas, permisos, vuelos, seguro, escuela de la hija, MSP, universidad, recomendaciones de socios, etc.). Son extremadamente confiables y competentes. Recomiendo mucho la empresa para quien...',
    depoimento4_location: 'Vancouver, Canadá',
    depoimento5: 'Recomiendo altamente el trabajo de Canadá Sin Fronteras porque a través de la prestación de un servicio serio (sin engaños ni dilaciones), con transparencia y gran atención a los detalles, revertimos una negativa de visa para Canadá en 2019. Mi familia y yo siempre estaremos agradecidos por haber mirado nuestro proceso con tanto cuidado, por las orientaciones precisas y directas. Ustedes son increíbles.',
    depoimento5_location: 'Vancouver, Canadá',
    title9: 'Todo en un solo lugar',
    subtitle9: 'Ven a Next Intake',
  },
  O_que_fazemos: {
    title1: 'Tenemos un Sueño',
    subtitle1: 'Queremos más personas como tú estudiando en Canadá',
    button_o_que_fazemos: 'Qué Hacemos',
    button_cadastre_agora: 'Regístrate Ahora',
    button_como_funciona: 'Cómo funciona',
    title2: 'Qué Hacemos',
    title2_1: 'Somos una plataforma que ofrece intercambios que se ajustan a tu presupuesto',
    title2_1_subtitle: 'Nos ocupamos de todas las etapas de organización de un viaje de estudio, conectándote con escuelas de idiomas, colegios, universidades y proveedores de servicios. Te inscribes y disfrutas de este período en Canadá al máximo y de la mejor manera.',
    title2_2: 'Ofrecemos cashback real a través de un programa de recompensas',
    title2_2_subtitle: 'Nuestra herramienta fue diseñada con una característica única: opera con un programa de puntos de fidelidad y cashback que reduce la inversión total en un intercambio. Esta idea fue desarrollada para ayudar a muchas más personas a realizar el sueño de estudiar en el extranjero.',
    title2_3: 'Hablamos tu idioma mientras aún mejoras tu inglés',
    title2_3_subtitle: 'Todo el contenido presentado, incluida la comunicación con nuestro equipo, está completamente en portugués. Esto es valioso, especialmente para estudiantes que aún no dominan el inglés, ofreciendo más comodidad a nuestros usuarios.',
    title2_4: 'Somos una empresa canadiense con operaciones en varios países',
    title2_4_subtitle: 'La plataforma nació para facilitar el acceso a cursos y servicios de intercambio en Canadá para estudiantes latinos. En 2020, fundamos la empresa en Canadá y definimos toda la programación de las funcionalidades de Next Intake.',
    title3: 'Somos la solución completa',
    // title3_1: 'Somos la solución completa',
    title3_1: ': cursos, visas, alojamiento,',
    title3_2: 'traducciones certificadas, seguro de salud y mucho más.',
    subtitle3: 'Aquí encuentras todo en línea para organizar tu viaje de estudio en Canadá.',
    subtitle3_1: 'Al inscribirte a través de la plataforma y acceder a otros servicios, ganas puntos de fidelidad.',
    subtitle3_2: 'Cada punto se devuelve como cashback.',
    title4: 'Nuestras metas hasta 2026',
    indicator_1_Number: '+4,000',
    indicator_1_Description_1: 'estudiantes',
    indicator_1_Description_2: 'asistidos',
    indicator_2_Number: '+500',
    indicator_2_Description_1: 'escuelas',
    indicator_2_Description_2: 'asociadas',
    indicator_3_Number: '+100',
    indicator_3_Description_1: 'proveedores de',
    indicator_3_Description_2: 'servicios',
    indicator_4_Number: '+1 millón',
    indicator_4_Description_1: 'puntos',
    indicator_4_Description_2: 'ofrecidos',
    title5: 'Sé Parte de Este Sueño También',
    subtitle5: 'Ven a Next Intake',
  },
  Como_funciona: {
    title1: 'Entiende Cómo Funciona',
    subtitle1: 'Conoce la plataforma de intercambio Next Intake y el programa de recompensas con cashback',
    button_o_que_fazemos: 'Qué Hacemos',
    button_cadastre_agora: 'Regístrate Ahora',
    button_como_funciona: 'Cómo funciona',
    title2: 'Entiende Cómo Funciona',
    indicator_1_Number: '1er paso',
    indicator_1_Description_1: 'Regístrate',
    indicator_1_Description_2: 'en la',
    indicator_1_Description_3: 'plataforma',
    indicator_2_Number: '2do paso',
    indicator_2_Description_1: 'Elige dónde',
    indicator_2_Description_2: 'estudiar',
    indicator_3_Number: '3er paso',
    indicator_3_Description_1: 'Contrata otros',
    indicator_3_Description_2: 'servicios',
    indicator_4_Number: '4to paso',
    indicator_4_Description_1: 'Acumula',
    indicator_4_Description_2: 'puntos',
    title3: 'Somos la solución completa',
    title3_1: ': cursos, visas, alojamiento,',
    title3_2: 'traducciones certificadas, seguro de salud y mucho más.',
    subtitle3: 'Aquí el estudiante encuentra todo en línea para organizar su viaje de estudios.',
    subtitle3_1: 'Al inscribirse en la plataforma, comienza a ganar puntos que generan descuentos para',
    subtitle3_2: 'la compra de otros servicios.',
    title4: 'Con nosotros, ganas puntos y cashback',
    subtitle4: 'Además de no cobrar ninguna tarifa, también ganas puntos que se convierten en cashback. Cada registro y servicio que contratas con nosotros genera puntos que hacen que tu intercambio sea asequible.',
    title5: 'Sé Parte de Este Sueño También',
    subtitle5: 'Ven a Next Intake'
  },
  afiliado: {
    title1: 'Sé un Afiliado',
    subtitle1: 'Cada vez que refieres a alguien que se convierte en estudiante, ganas',
    button_o_que_fazemos: 'Qué Hacemos',
    button_cadastre_agora: 'Regístrate Ahora',
    button_como_funciona: 'Cómo funciona',
    title2: 'Mira Cómo Convertirte en un Afiliado',
    indicator_1_Number: '1er paso',
    indicator_1_Description_1: 'Regístrate',
    indicator_1_Description_2: 'en la',
    indicator_1_Description_3: 'plataforma',
    indicator_2_Number: '2º paso',
    indicator_2_Description_1: 'Refiere estudiantes',
    indicator_2_Description_2: 'a través de un',
    indicator_2_Description_3: 'enlace exclusivo',
    indicator_3_Number: '3º paso',
    indicator_3_Description_1: 'Recibes un bono',
    indicator_3_Description_2: 'cuando el estudiante llega a Canadá',
    indicator_4_Number: '4º paso',
    indicator_4_Description_1: 'Canjea tu bono',
    indicator_4_Description_2: 'o acumula más',
    title3_1: 'Somos la solución completa',
    title3_2: ': cursos, visas, alojamiento,',
    title3_3: 'traducciones certificadas, seguro de salud y mucho más.',
    subtitle3: 'quí encuentras todo en línea para organizar tu viaje de estudio en Canadá.',
    subtitle3_1: 'Al inscribirte a través de la plataforma y acceder a otros servicios, ganas puntos de fidelidad.',
    subtitle3_2: 'Cada punto se devuelve como cashback.',
    title4: 'Compartimos nuestro beneficio contigo para que todos ganen',
    subtitle4: 'En nuestro sistema, acumulas puntos mediante referencias de estudiantes a Next Intake. Estos puntos se convierten en cashback cuando los estudiantes llegan a Canadá. Nuestra misión es ayudar a más personas a alcanzar su sueño de estudiar en el extranjero.',
    title5: 'Promueve Esta Oportunidad a Más Estudiantes',
    subtitle5: 'Ven a Next Intake',
  },
  parceiro: {
    title1: 'Sé un Socio',
    subtitle1: 'Nuestra plataforma es el puente entre las escuelas canadienses y los estudiantes brasileños',
    button_o_que_fazemos: 'Qué Hacemos',
    button_cadastre_agora: 'Regístrate Ahora',
    button_como_funciona: 'Cómo funciona',
    button_cadastre_escola: 'Registra Tu Escuela',
    button_cadastre_empresa: 'Registra Tu Empresa',
    title2: 'Cómo trabajamos',
    card_title1: 'Nuestra misión',
    card_title1_description: 'Desarrollamos Next Intake para ofrecer a más estudiantes brasileños la oportunidad de estudiar en Canadá.',
    card_title2: 'Alta Calidad',
    card_title2_description: 'Para ahorrar tiempo y esfuerzo de tu institución, solo iniciamos el proceso de aplicación después de una revisión minuciosa de los documentos.',
    card_title3: 'Cómo lo Hacemos',
    card_title3_description: 'Nuestro equipo apoya al estudiante en cada etapa de la preparación del intercambio a través de los recursos ofrecidos en la plataforma.',
    title3: 'Somos la solución completa',
    title3_1: ': cursos, visas, alojamiento,',
    title3_2: 'traducciones certificadas, seguro de salud y mucho más.',
    subtitle3: 'Aquí el estudiante encuentra todo en línea para organizar su viaje de estudios.',
    subtitle3_1: 'Al inscribirse en la plataforma, comienza a ganar puntos que generan descuentos para',
    subtitle3_2: 'la compra de otros servicios.',
    title4: 'Cómo Promovemos Tu Marca',
    subtitle4: 'En Next Intake, la página de tu institución o empresa proveedora de servicios se presenta con información que destaca tu diferencial en el mercado educativo.',
    title5: 'Somos diferentes',
    title5_1: 'El futuro en línea es ahora.',
    subtitle5: 'Con esto en mente, ofrecemos una experiencia completa de organización de intercambio en nuestra plataforma. Nuestro ',
    subtitle5_1: 'programa único de recompensas',
    subtitle5_2: 'incentiva al estudiante a completar todas las etapas en Next Intake con el apoyo de nuestro equipo y nuestros socios.',
    title6: 'Sé Visto y Atrae Más Estudiantes',
    subtitle6: 'Ven a Next Intake'    
  },
  questions: {
    title1: 'Preguntas frecuentes',
    subtitle1: 'Resuelve aquí tus dudas sobre Next Intake',
    title2: 'Sobre la plataforma Next Intake',
    question1: '¿Qué es',
    answers1: 'Next Intake es una plataforma de búsqueda de programas educativos que asiste a estudiantes internacionales en la planificación y ejecución de todas las tareas preparatorias para sus jornadas de estudio en Canadá, ofreciendo soporte en el idioma nativo, además de un programa único de recompensas con cashback.',
    
    question2: '¿Qué opciones de estudio encuentro en la',
    answers2: 'En Next Intake encuentras cursos de idiomas, programas de high school (Educación Secundaria), licenciaturas y posgrados en colegios (equivalente a facultades), así como licenciaturas y maestrías en universidades canadienses.',    
    
    question3: '¿Cómo puede la ',
    question3_1: 'ayudarme a estudiar en Canadá?',
    answers3: 'Next Intake simplifica el proceso de estudio en Canadá, ofreciendo servicios como inscripción en programas educativos, organización de alojamiento, asistencia para visas, traducciones de documentos y mucho más.',
    
    question4: '¿Quién puede usar la',
    answers4: 'Además de atender a estudiantes internacionales, Next Intake tiene un programa de afiliados. Los afiliados pueden referir estudiantes a la plataforma y ser compensados cuando estos estudiantes llegan a Canadá.',
    
    question5: '¿Cuáles son los principales beneficios de usar la', 
    answers5: 'Los principales beneficios incluyen acceso a una amplia red de instituciones educativas canadienses y proveedores de servicios, asistencia personalizada en cada paso del proceso, soporte ofrecido en portugués y español, y un programa de recompensas con cashback.',
    
    question6: '¿Cómo funciona el programa de recompensas con cashback?',
    answers6: 'Nuestro programa de recompensas permite que los estudiantes acumulen puntos al usar nuestros servicios y estos puntos pueden convertirse en cashback una vez que estés en Canadá.',
    
    question7: '¿La',
    question7_1: 'proporciona apoyo durante todo el proceso de matrícula?',
    answers7: 'Sí, ofrecemos apoyo completo para guiarte en la búsqueda de programas de estudio en Canadá y en la organización de documentos para realizar la matrícula de acuerdo con las exigencias de la escuela.',    
    
    question8: '¿Qué servicios ofrece la',
    question8_1: 'además de la matrícula en programas educativos?',
    answers8: 'Además de la matrícula, ofrecemos servicios como traducción de documentos, asesoría para solicitud de visa, búsqueda de alojamiento, seguro de salud, servicios de telefonía móvil, servicios bancarios y mucho más.',
    
    question9: '¿',
    question9_1: 'cobra alguna tarifa a los estudiantes?',
    answers9: 'No, nuestros servicios son gratuitos para los estudiantes. Next Intake es compensada a través de comisiones pagadas por las instituciones educativas y proveedores de servicios que están listados en nuestra plataforma.',    
    
    question10: '¿Cómo empiezo a usar la ',
    answers10: 'Simplemente regístrate en nuestro sitio web y comienza a explorar las opciones de programas educativos y servicios. Nuestro equipo estará disponible para guiarte en cada etapa del proceso.',
    
    question11: '¿Cómo selecciona la',
    question11_1: 'las instituciones asociadas? ',
    answers11: 'Seleccionamos nuestras instituciones asociadas basadas en su reputación, la calidad de la enseñanza y su capacidad para proporcionar apoyo adecuado a los estudiantes internacionales. Solo trabajamos con instituciones que comparten nuestro compromiso con la excelencia educativa.',
    
    question12: '¿Cuál es el horario de atención de la ',
    answers12: 'Next Intake opera 24 horas al día, siete días a la semana. Durante tu proceso de matrícula, interactúas con nuestro equipo de expertos según el horario comercial de tu región.',
    
    card1_title: 'Ubicación',
    card1_description: 'Vancouver, Canadá',
    card2_title: 'Correo electrónico',
    card2_description: 'support@nextintake.com',
    card3_title: 'Teléfono',
    card3_description: '+1 (716) 220 8658',
    card4_title: 'WhatsApp',
    card4_description: '+1 (716) 220 8658',
    form_title: 'Envía un mensaje',
    form_input1_label: 'Nombre completo',
    form_input1_placeholder: 'Nombre Apellido',
    form_input2_label: 'Correo electrónico',
    form_input2_placeholder: 'mi@correo.com',
    form_input3_label: 'Asunto',
    form_input3_option1: 'Selecciona un Asunto',
    form_input3_option2: 'Información sobre Cursos e Inscripción',
    form_input3_option3: 'Dudas sobre Visas',
    form_input3_option4: 'Dudas sobre Proveedores de Servicios',
    form_input3_option5: 'Soporte Técnico',
    form_input3_option6: 'Otro Asunto',
    form_input4_label: 'Descripción',
    form_input4_placeholder: 'Proporciona más detalles sobre el propósito de tu contacto...',
    button_send_message: 'Enviar mensaje',
    button_send_message_1: 'Espera',
    button_send_fechar: 'Espera',
  },
  blog: {
    title: 'Blog de Next Intake',
    title2: 'Énfasis',
    title3: 'Más popular',
    noPosts: '- No hay publicaciones registradas -',
    loading: 'Cargando...',
    btnBuscar: 'Buscar',
    placeholder: 'Introduce lo que buscas aquí',
    title4: '¿Te gusta lo que ves aquí?',
    title5: 'Suscríbete a nuestra newsletter y recibe nuevos contenidos en tu correo electrónico',
    input1: 'Nombre completo',
    input1Placeholder: 'Nombre Apellido',
    input2: 'Correo electrónico',
    input2Placeholder: 'meu@email.com',
    checkbox: 'Me gustaría recibir contenido nuevo del Blog de Next Intake',
    btnSend: 'Quiero recibir',
  } 
};
