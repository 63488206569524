import { useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Public/Header";
import styles from "../Home/Home.module.scss";
import ManBaggage from "../../../Assets/images/man-with-baggage.png";
import Course from "../../../Assets/images/course.png";
import ImageCourse2 from "../../../Assets/images/course2.png";
import Image01 from "../../../Assets/images/image01.png";
import Image02 from "../../../Assets/images/image02.png";
import Image03 from "../../../Assets/images/image03.png";
import Image04 from "../../../Assets/images/image04.png";
import { Button } from "../../../Components/Button";
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.css";
import { ConteinerImage } from "./conteinerImage";
import { Footer } from "../../../Components/Public/Footer";

import Testimony01 from "../../../Assets/images/testimony_1.png";
import Testimony02 from "../../../Assets/images/testimony_2.png";
import Testimony03 from "../../../Assets/images/testimony_3.png";
import Testimony04 from "../../../Assets/images/testimony_4.png";
import Testimony05 from "../../../Assets/images/testimony_5.png";
import { useTranslation } from 'react-i18next'



export function Home() {
  const { t } = useTranslation()
  const navigate = useNavigate();

  function handleWhatWeDo() {
    navigate("/o-que-fazemos");
  }

  function handleRegist() {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/subscribe`
    // navigate("/cadastre-se");
  }

  function handleBeAnAffiliate() {
    navigate("/seja-um-afiliado");
  }

  function handleBeAPartner() {
    navigate("/seja-um-parceiro");
  }

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  const testimonies = [
    { avatar: Testimony01, name: 'Jorge de Andrade', location: t('home.depoimento1_location'), evaluation: 5, testimony: t('home.depoimento1') },
    { avatar: Testimony02, name: 'Helena Prado', location: t('home.depoimento2_location'), evaluation: 5, testimony: t('home.depoimento2') },
    { avatar: Testimony03, name: 'Gustavo Liber', location: t('home.depoimento3_location'), evaluation: 5, testimony: t('home.depoimento3') },
    { avatar: Testimony04, name: 'Hamilton Absy Junior', location: t('home.depoimento4_location'), evaluation: 5, testimony: t('home.depoimento4') },
    { avatar: Testimony05, name: 'Livia Almeida', location: t('home.depoimento5_location'), evaluation: 5, testimony: t('home.depoimento5') },
  ]

  const newItems = testimonies.map((item: any, index: any) => <ConteinerImage key={index} item={item} />)

  const items: any[] = [
    // <ConteinerImage key={1} />,
    // <ConteinerImage key={2} />,
    // <ConteinerImage key={3} />,
    // <ConteinerImage key={4} />,
    // <ConteinerImage key={5} />,
  ];

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const hidden = false;

  return (
    <div id="page-top">
      <Header />
      <header className={`masthead ${styles.division01}`}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <div className={`${styles.title01}`}>{t('home.title')}</div>
                <div className={`${styles.subTitle01}`}>
                  {t('home.subTitle')}
                </div>
                <div
                  className={`d-flex flex-column flex-lg-row align-items-center ${styles.btnDivision01} ${styles.deviceDivision01}`}
                >
                  <div
                    className="me-lg-3 mb-4 mb-lg-0"
                    onClick={handleWhatWeDo}
                  >
                    <Button name={t('home.button_o_que_fazemos')} typeButton="secondary" />
                  </div>
                  <div onClick={handleRegist}>
                    <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img
                  src={ManBaggage}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
            <div
              className={`d-flex flex-lg-row mt-3 justify-content-center align-items-center ${styles.deviceDivision01_02}`}
            >
              <a className="me-lg-3 mb-lg-0 m-2" href="#!">
                <Button name="O que fazemos" typeButton="secondary" />
              </a>
              <a href="#!" className="m-2">
                <Button name="Cadastre-se agora" typeButton="primary" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className={`text-center ${styles.division02}`}>
        <div className={`container px-5 ${styles.subContainer}`}>
          <div className="col-lg-12">
            <div className="mb-5 text-lg-start">
              <p className={`mb-5 ${styles.title02}`}>
              {t('home.title2')}
              </p>
            </div>
          </div>
          <div className={styles.cardYourBrand}>
            <div className="row gx-5 mb-3">              
                {
                  [0, 1, 2, 3, 4, 5].map((item, index) => {
                    return (
                      <div className="col-xxl-2 col-md-6">
                        <div
                          className={`d-flex align-items-center justify-content-center ${styles.yourBrandHere}`}
                        >
                          <span className={styles.textBrand}>
                          {t('home.brand1')}
                            <br /> {t('home.brand2')}
                          </span>
                          <span className={styles.textBrandHere}>{t('home.brand3')}</span>
                        </div>
                      </div>
                    )
                  })
                }                                        
            </div>

            <div className={styles.cardYourBrand}>
              <div className="row gx-5 mb-3">              
                  {
                    [0, 1, 2, 3, 4, 5].map((item, index) => {
                      return (
                        <div className="col-xxl-2 col-md-6">
                          <div
                            className={`d-flex align-items-center justify-content-center ${styles.yourBrandHere}`}
                          >
                            <span className={styles.textBrand}>
                            {t('home.brand1')}
                              <br /> {t('home.brand2')}
                            </span>
                            <span className={styles.textBrandHere}>{t('home.brand3')}</span>
                          </div>
                        </div>
                      )
                    })
                  }                                        
              </div>                        
            </div>                        
          </div>
        </div>
      </section>
      <section className={styles.division03}>
        <div className="container px-5">
          <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03}`}
          >
            <span>{t('home.title3_1')}</span>{t('home.title3_2')}
            <br />{t('home.title3_3')}
          </div>
          <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03_01}`}
          >
            <span>{t('home.title3_1')}</span>{t('home.title3_2')}
            {t('home.title3_3')}
          </div>
          <div className="col-lg-12">
            <div className="masthead-device-mockup">
              <img
                src={Course}
                style={{ width: "100%", height: "100%" }}
                alt="Imagem"
              />
            </div>
          </div>
          <div className={styles.subTitle03}>
            {t('home.subtitle3_1')}<br />{t('home.subtitle3_2')}<br />{t('home.subtitle3_3')}
          </div>
          <div
            className={`d-flex flex-column flex-lg-row align-items-center justify-content-center ${styles.deviceDivision03}`}
          >
            <div className="me-lg-3 mb-4 mb-lg-0" onClick={handleBeAPartner}>
              <Button
                name={t('home.button_o_que_fazemos')}
                typeButton="secondary"
                color="black"
              />
            </div>
            <div onClick={handleRegist}>
              <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
            </div>
          </div>

          <div
            className={`d-flex flex-lg-row align-items-center justify-content-center ${styles.deviceDivision03_01}`}
          >
            <div className="me-lg-3 mb-lg-0 m-2" onClick={handleBeAPartner}>
              <Button
                name={t('home.button_o_que_fazemos')}
                typeButton="secondary"
                color="black"
              />
            </div>
            <a href="#!" className="m-2">
              <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
            </a>
          </div>
        </div>
        <div className={`col-lg-12 ${styles.imageCourse2}`}>
          <div className="masthead-device-mockup">
            <img src={ImageCourse2} alt="Imagem" />
          </div>
        </div>
      </section>
      <section className={styles.division04}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <div className={styles.title04}>{t('home.title4')}</div>
                <div className={styles.subTitle04}>
                  {t('home.subtitle4')}
                </div>
                <div className={`col-lg-6 ${styles.deviceDivision04_01}`}>
                  <div className="masthead-device-mockup">
                    <img
                      src={Image01}
                      style={{ width: "100%", height: "100%" }}
                      alt="Imagem"
                    />
                  </div>
                </div>
                {hidden && (
                  <div className="d-flex flex-column flex-lg-row align-items-center">
                    <a className="me-lg-3 mb-4 mb-lg-0" href="#!">
                      <Button
                        name={t('home.button_pesquise_outros_servicos')}
                        typeButton="secondary"
                        color="black"
                      />
                    </a>
                    <a href="#!">
                      <Button
                        name={t('home.button_pesquise_onde_estudar')}
                        typeButton="primary"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className={`col-lg-6 ${styles.deviceDivision04}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image01}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.division05}>
        <div className={`container px-5`}>
          <div className={`row gx-5 align-items-start`}>
            <div className={`col-lg-6 ${styles.deviceDivision05}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image02}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
            <div className={`col-lg-6 ${styles.containerDivision05}`}>
              <div className="mb-lg-0 text-center text-lg-start">
                <div className={styles.title05}>{t('home.title5')}</div>
                <div className={styles.subTitle05}>
                  {t('home.subtitle5')}
                </div>
              </div>
            </div>
            <div className={`col-lg-6 ${styles.deviceDivision05_01}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image02}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.division06}>
        <div className={`masthead ${styles.container06ColorTop}`}>
          <div
            className={`gx-5 align-items-center ${styles.container06ColorTop_02}`}
          >
            <div className={`col-lg-6 ${styles.deviceDivision06}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image03}
                  className={styles.imageDivision06}
                  alt="Imagem"
                />
              </div>
            </div>
            <div className="col-lg-6 container px-5">
              <div className="mb-lg-0 text-center text-lg-start">
                <div className={styles.title06}>{t('home.title6')}</div>
                <div className={styles.subTitle06}>
                  {t('home.subtitle6_1')}
                  <br />
                  {t('home.subtitle6_2')}
                  <br />
                  {t('home.subtitle6_3')}
                  <br />
                  {t('home.subtitle6_4')}                                                
                </div>
                <div
                  className={`d-flex flex-column flex-lg-row align-items-center ${styles.deviceDivision06}`}
                >
                  <div
                    className="me-lg-3 mb-4 mb-lg-0"
                    onClick={handleBeAnAffiliate}
                  >
                    <Button
                      name={t('home.button_entenda_como')}
                      typeButton="secondary"
                      color="black"
                    />
                  </div>
                  <div onClick={handleRegist}>
                    <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-lg-6 ${styles.deviceDivision06_01} ${styles.deviceDivision06_image}`}
            >
              <div className="masthead-device-mockup">
                <img
                  src={Image03}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
            <div
              className={`d-flex flex-lg-row mt-5 justify-content-center align-items-center ${styles.deviceDivision06_01} ${styles.deviceDivision06_btn}`}
            >
              <a className="me-lg-3 mb-lg-0 m-2" href="#!">
                <Button
                  name={t('home.button_entenda_como')}
                  typeButton="secondary"
                  color="black"
                />
              </a>
              <a href="#!" className="m-2">
                <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.division07}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <div className={styles.title07}>{t('home.title7')}</div>
                <div className={styles.subTitle07}>
                  {t('home.subtitle7_1')}
                  {t('home.subtitle7_2')}
                  {t('home.subtitle7_3')}
                </div>
                <div
                  className={`d-flex flex-column flex-lg-row align-items-center ${styles.deviceDivision07}`}
                >
                  <div
                    className="me-lg-3 mb-4 mb-lg-0"
                    onClick={handleBeAPartner}
                  >
                    <Button name={t('home.button_entenda_como')} typeButton="secondary" />
                  </div>
                  <div onClick={handleRegist}>
                    <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-6 ${styles.deviceDivision07_image}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image04}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
            <div
              className={`d-flex flex-lg-row mt-5 justify-content-center align-items-center ${styles.deviceDivision07_01}`}
            >
              <div className="me-lg-3 mb-lg-0 m-2" onClick={handleBeAPartner}>
                <Button
                  name={t('home.button_entenda_como')}
                  typeButton="secondary"
                  color="white"
                />
              </div>
              <div className="m-2" onClick={handleRegist}>
                <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.containerCard08}>
        <div className={`px-5`}>
          <div className="row gx-5">
            <div className="col-lg-12">
              <div className={`mb-5 mb-lg-0 text-center carouselHome`}>
                <div className={styles.text08}>{t('home.title8')}</div>
                <AliceCarousel
                  mouseTracking
                  // items={items}
                  items={newItems}
                  responsive={responsive}
                  disableDotsControls={true}

                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.division09}>
        <div className={`masthead ${styles.division09ColorTop}`}>
          <div className="container px-5">
            <div className={styles.title09}>{t('home.title9')}</div>
            <div className={styles.subTitle09}>{t('home.subtitle9')}</div>
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
              <div className="me-lg-3 mb-4 mb-lg-0" onClick={handleRegist}>
                <Button name={t('home.button_cadastre_agora')} typeButton="primary" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
