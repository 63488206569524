import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "../EnglishProficiency/EnglishProficiency.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function EnglishProficiency() {
  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate("/documentos-adicionais");
  };

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={5}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Proficiência em inglês</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <div className={`form-label ${styles.firstDesc}`}>
                  Todos os candidatos são obrigados a demonstrar seu nível de
                  proficiência em inglês.
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Como você demonstrou seu nível de proficiência em inglês?
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Teste de inglês padronizado</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Teste<span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Data de realização
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Pontuação geral (Overall)
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Pontuação de escuta (Listening)
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Pontuação de conversação (Speaking)
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Pontuação de compreensão de texto (Reading)
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Pontuação de escrita textual (Writing)
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Número de identificação (Test report form - TRF no.)
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Anexar o relatório
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <div className={styles.containerPassport01}>
                    <div className={styles.containerPassport02}>
                      <div className={styles.textPassport01}>
                        Solte o arquivo para anexar ou
                        <span className={styles.textPassport02}> procure</span>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <div className="col-md-12" onClick={() => handleNextScreen()}>
                  <Button name="Próxima etapa" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
