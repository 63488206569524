import styles from "./Footer.module.scss";
import image1 from "../../../Assets/images/icon/image33.png";
import image2 from "../../../Assets/images/icon/image34.png";
import image3 from "../../../Assets/images/icon/image35.png";
import image4 from "../../../Assets/images/icon/image36.png";
import image5 from "../../../Assets/images/logo2.png";

export function Footer() {
  return (
    <footer className={`text-center ${styles.backgroundColor}`}>
      <div className={`${styles.containerImages}`}>
        <div className={styles.containerLogo}>
          <span>Powered by</span>
          <img
            className={`app-badge ${styles.imageLogo}`}
            src={image5}
            alt="..."
          />
        </div>
        <div className={`text-white-50 small ${styles.containerIcons}`}>
          <div>
            <img
              className={`app-badge ${styles.imageIcons}`}
              src={image1}
              alt="..."
            />
            <img
              className={`app-badge ${styles.imageIcons}`}
              src={image2}
              alt="..."
            />
            <img
              className={`app-badge ${styles.imageIcons}`}
              src={image3}
              alt="..."
            />
            <img
              className={`app-badge ${styles.imageIcons}`}
              src={image4}
              alt="..."
            />
          </div>
          <div>
            <div className={styles.text1}>©2024 Canadá Sem Fronteiras</div>
            <div className={styles.text2}>©2024 Next Intake</div>
          </div>
        </div>
        <div className={styles.spaceRight} />
      </div>
    </footer>
  );
}
