import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "../AdditionalDocuments/AdditionalDocuments.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function AdditionalDocuments() {
  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate("/premiacoes");
  };

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={6}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Documentos adicionais</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <div className={`form-label ${styles.infoTitle}`}>
                  Se pretender incluir documentos comprovativos adicionais que
                  não tenham sido especificamente solicitados, pode anexá-los
                  abaixo.
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    CV
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <div className={styles.containerPassport01}>
                    <div className={styles.containerPassport02}>
                      <div className={styles.textPassport01}>
                        Solte o arquivo para anexar ou
                        <span className={styles.textPassport02}> procure</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Certificado de Conclusão
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <div className={styles.containerPassport01}>
                    <div className={styles.containerPassport02}>
                      <div className={styles.textPassport01}>
                        Solte o arquivo para anexar ou
                        <span className={styles.textPassport02}> procure</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`form-label ${styles.infoDesc}`}>
                  Para estudantes que se candidatam ao programa de graduação
                  (BCOM, BA ou AA) — High School Diploma
                </div>

                <div className={`form-label ${styles.infoDesc}`}>
                  Para os alunos que se candidatam ao programa de nível de
                  pós-graduação (MBA) - bacharelado e/ou mestrado
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Outro Certificado
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <div className={styles.containerPassport01}>
                    <div className={styles.containerPassport02}>
                      <div className={styles.textPassport01}>
                        Solte o arquivo para anexar ou
                        <span className={styles.textPassport02}> procure</span>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <div className="col-md-12" onClick={() => handleNextScreen()}>
                  <Button name="Próxima etapa" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
