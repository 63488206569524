import "react-alice-carousel/lib/alice-carousel.css";

import styles from "./TimeLineComp.module.scss";
import Image25 from "../../../Assets/images/icon/image25.png";

interface Props {
  iconChecked: boolean;
  positionIcon?: number;
  children?: React.ReactNode;
}

export function TimeLineComp({
  iconChecked,
  positionIcon = 0,
  children,
}: Props) {
  const imageIcon = (status: boolean, position: number) => {
    if (status && position > 0) {
      return (
        <div className={styles.circleChecked}>
          <img
            className={`app-badge ${styles.iconCircle}`}
            src={Image25}
            alt="..."
          />
        </div>
      );
    } else if (position <= 0) {
      return <div className={styles.circle} />;
    } else {
      return <div className={styles.circleChecked} />;
    }
  };

  const arrayPosition = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div className="container px-5 col-12">
      <div className={styles.containerTimeLine}>
        {arrayPosition.map((value) => {
          const result =
            value === arrayPosition.length ? (
              ""
            ) : (
              <div className={styles.dividerTimeLine} />
            );

          const totalPosition = positionIcon > 9 ? 9 : positionIcon;

          return (
            <div className={styles.wrapping} key={value.toString()}>
              <div className={styles.contentTimeLine}>
                {imageIcon(
                  iconChecked,
                  value <= totalPosition ? totalPosition : 0
                )}
              </div>
              {value < totalPosition ? (
                <div className={styles.dividerTimeLineBlue} />
              ) : (
                result
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.containerTimeLine}>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Informação
            <br /> do programa
          </span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Informações
            <br /> pessoais
          </span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Contato de
            <br /> emergência
          </span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Histórico
            <br /> escolar
          </span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Proficiência
            <br /> em inglês
          </span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Documentos
            <br /> adicionais
          </span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>Premiações</span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Revisar e<br /> concluir
          </span>
        </div>
        <div className={styles.contentTimeLine}>
          <div className={styles.circleText} />
          <span className={styles.textTimeLine}>
            Termos e<br /> condições
          </span>
        </div>
      </div>

      {children}
    </div>
  );
}
