import { useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import logo from "../../../Assets/images/logo-two.png";
import Image17 from "../../../Assets/images/icon/image17.png";
import Image18 from "../../../Assets/images/icon/image18.png";
import Image19 from "../../../Assets/images/icon/image19.png";
import Image20 from "../../../Assets/images/icon/image20.png";
import Image21 from "../../../Assets/images/icon/image21.png";
import Image22 from "../../../Assets/images/icon/image22.png";
import Image23 from "../../../Assets/images/icon/image23.png";
import Image24 from "../../../Assets/images/icon/image24.png";
import { Button } from "../../Button";

export function Header() {
  const navigate = useNavigate();

  function handleNextScreen() {
    navigate("/boas-vindas");
  }

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top ${styles.background}`}
      id="mainNav"
    >
      <div className="container">
        <div className={styles.containerImageForm}>
          <div onClick={handleNextScreen}>
            <img
              className={`app-badge ${styles.imageLogo}`}
              src={logo}
              alt="..."
            />
          </div>
          <form
            className={`row gx-3 gy-2 align-items-center ${styles.formContainer}`}
          >
            <div className={`col-sm-5`}>
              <div className={`input-group ${styles.inputGroupTop01}`}>
                <div className={styles.inputGroupText}>
                  <img
                    className={`app-badge ${styles.iconInput}`}
                    src={Image23}
                    alt="..."
                  />
                </div>
                <input
                  type="text"
                  className={`form-control ${styles.textInput}`}
                  placeholder="O que você procura?"
                />
              </div>
            </div>

            <div className={`col-sm-5 ${styles.groupInputBtn}`}>
              <div className={`input-group ${styles.inputGroupTop}`}>
                <div className={styles.inputGroupText}>
                  <img
                    className={`app-badge ${styles.iconInput}`}
                    src={Image24}
                    alt="..."
                  />
                </div>
                <input
                  type="text"
                  className={`form-control ${styles.textInput}`}
                  id="inlineFormInputGroupUsername"
                  placeholder="Onde?"
                />
              </div>
              <div className="col-sm-3">
                <Button name="Pesquisar" height={52} typeButton="primary" />
              </div>
            </div>
          </form>
        </div>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <div className="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <div className={styles.containerIcon}>
              <img
                className={`app-badge ${styles.imageIcon}`}
                src={Image17}
                alt="..."
              />
              <span className={styles.textIcon}>Dashboard</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.containerIcon}>
              <img
                className={`app-badge ${styles.imageIcon}`}
                src={Image18}
                alt="..."
              />
              <span className={styles.textIcon}>Programas</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.containerIcon}>
              <img
                className={`app-badge ${styles.imageIcon}`}
                src={Image19}
                alt="..."
              />
              <span className={styles.textIcon}>Pagamentos</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.containerIcon}>
              <img
                className={`app-badge ${styles.imageIcon}`}
                src={Image20}
                alt="..."
              />
              <span className={styles.textIcon}>Mensagens</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.containerIcon}>
              <img
                className={`app-badge ${styles.imageIcon}`}
                src={Image21}
                alt="..."
              />
              <span className={styles.textIcon}>Notificações</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.containerIcon}>
              <div className={styles.containerIconProfile}>
                <img
                  className={`app-badge ${styles.imageProfile}`}
                  src={Image22}
                  alt="..."
                />
              </div>
              <span className={styles.textProfile}>Perfil</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
