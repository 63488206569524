import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Public/Header";
import styles from "../WhatWeDo/WhatWeDo.module.scss";
import Image00 from "../../../Assets/images/image08.png";
import ImageCourse2 from "../../../Assets/images/course2.png";
import Image08 from "../../../Assets/images/image09.png";
import Image01Division02 from "../../../Assets/images/icon/image01.png";
import Image02Division02 from "../../../Assets/images/icon/image02.png";
import Image03Division02 from "../../../Assets/images/icon/image03.png";
import Image04Division02 from "../../../Assets/images/icon/image04.png";
import Image05Division02 from "../../../Assets/images/icon/image05.png";
import Image06Division02 from "../../../Assets/images/icon/image06.png";
import { Button } from "../../../Components/Button";
import "react-alice-carousel/lib/alice-carousel.css";
import { Footer } from "../../../Components/Public/Footer";
import { useTranslation } from "react-i18next";

export function WhatWeDo() {
  const { t } = useTranslation()
  const navigate = useNavigate();

  function handleHowItWorks() {
    navigate("/como-funciona");
  }

  function handleRegist() {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/subscribe`
    // navigate("/cadastre-se");
  }

  function handleWhatWeDo() {
    navigate("/o-que-fazemos");
  }

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header position={0} />
      <header className={`masthead ${styles.division01}`}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <div className={`${styles.title01}`}>{t('O_que_fazemos.title1')}</div>
                <div className={`${styles.subTitle01}`}>
                  {t('O_que_fazemos.subtitle1')}
                </div>
                <div
                  className={`d-flex flex-column flex-lg-row align-items-center ${styles.btnDivision01} ${styles.deviceDivision01}`}
                >
                  <div
                    className="me-lg-3 mb-4 mb-lg-0"
                    onClick={handleHowItWorks}
                  >
                    <Button name={t('O_que_fazemos.button_o_que_fazemos')} typeButton="secondary" />
                  </div>
                  <div onClick={handleRegist}>
                    <Button name={t('O_que_fazemos.button_cadastre_agora')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img
                  src={Image00}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
            <div
              className={`d-flex flex-lg-row mt-3 justify-content-center align-items-center ${styles.deviceDivision01_02}`}
            >
              <div className="me-lg-3 mb-lg-0 m-2" onClick={handleWhatWeDo}>
                <Button name={t('O_que_fazemos.button_o_que_fazemos')} typeButton="secondary" />
              </div>
              <div className="m-2" onClick={handleRegist}>
                <Button name={t('O_que_fazemos.button_cadastre_agora')} typeButton="primary" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className={styles.division02}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className={styles.title02}>{t('O_que_fazemos.title2')}</div>
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 flex-row d-flex">
                <img
                  src={Image01Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.containerText02}>
                  <div className={styles.titleText02}>
                    {t('O_que_fazemos.title2_1')}                  
                  </div>
                  <div className={styles.subText02}>
                    {t('O_que_fazemos.title2_1_subtitle')}
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-6`}>
              <div className="mb-5 mb-lg-0 flex-row d-flex">
                <img
                  src={Image02Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.containerText02}>
                  <div className={styles.titleText02}>
                    {t('O_que_fazemos.title2_2')}                            
                  </div>
                  <div className={styles.subText02}>
                    {t('O_que_fazemos.title2_2_subtitle')}                    
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 flex-row d-flex">
                <img
                  src={Image03Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.containerText02}>
                  <div className={styles.titleText02}>
                    {t('O_que_fazemos.title2_3')} 
                    
                  </div>
                  <div className={styles.subText02}>
                    {t('O_que_fazemos.title2_3_subtitle')}                    
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-6`}>
              <div className="mb-5 mb-lg-0 flex-row d-flex">
                <img
                  src={Image04Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.containerText02}>
                  <div className={styles.titleText02}>
                    {t('O_que_fazemos.title2_4')}                     
                  </div>
                  <div className={styles.subText02}>
                    {t('O_que_fazemos.title2_4_subtitle')}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.division03}>
        <div className="container px-5">
          <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03}`}
          >
            <span>{t('O_que_fazemos.title3')}</span>{t('O_que_fazemos.title3_1')}
            <br />{t('O_que_fazemos.title3_2')}
          </div>
          <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03_01}`}
          >
            <span>{t('O_que_fazemos.title3')}</span>{t('O_que_fazemos.title3_1')}
            {t('O_que_fazemos.title3_2')}            
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="masthead-device-mockup">
              <img
                src={Image08}
                style={{ width: "100%", height: "100%" }}
                alt="Imagem"
              />
            </div>
          </div>
          <div className={styles.subTitle03}>
            {t('O_que_fazemos.subtitle3')}
            <br />
            {t('O_que_fazemos.subtitle3_1')}
            <br />
            {t('O_que_fazemos.subtitle3_2')}
          </div>
          <div
            className={`d-flex flex-column flex-lg-row align-items-center justify-content-center ${styles.deviceDivision03}`}
          >
            <div className="me-lg-3 mb-4 mb-lg-0" onClick={handleHowItWorks}>
              <Button
                name={t('O_que_fazemos.button_como_funciona')}
                typeButton="secondary"
                color="black"
              />
            </div>
            <div onClick={handleRegist}>
              <Button name={t('O_que_fazemos.button_cadastre_agora')} typeButton="primary" />
            </div>
          </div>

          <div
            className={`d-flex flex-lg-row align-items-center justify-content-center ${styles.deviceDivision03_01}`}
          >
            <div className="me-lg-3 mb-lg-0 m-2" onClick={handleHowItWorks}>
              <Button
                name={t('O_que_fazemos.button_como_funciona')}
                typeButton="secondary"
                color="black"
              />
            </div>
            <div className="m-2" onClick={handleRegist}>
              <Button name={t('O_que_fazemos.button_cadastre_agora')} typeButton="primary" />
            </div>
          </div>
        </div>
        <div className={`col-lg-12 ${styles.imageCourse2}`}>
          <div className="masthead-device-mockup">
            <img src={ImageCourse2} alt="Imagem" />
          </div>
        </div>
      </section>
      {/* ************************************************************************* */}

      <section className={styles.division04}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className={styles.title04}>{t('O_que_fazemos.title4')}</div>
            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <img
                  src={Image01Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subTitle04}>{t('O_que_fazemos.indicator_1_Number')}</div>
                <div className={styles.TextDefaultDivision04}>{t('O_que_fazemos.indicator_1_Description_1')}</div>
                <div className={styles.Text01Division04}>{t('O_que_fazemos.indicator_1_Description_2')}</div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <img
                  src={Image05Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subTitle04}>{t('O_que_fazemos.indicator_2_Number')}</div>
                <div className="flex-row d-flex text-center">
                  <div className={styles.Text02Division04}>{t('O_que_fazemos.indicator_2_Description_1')}</div>
                  <div className={styles.TextDefaultDivision04}>{t('O_que_fazemos.indicator_2_Description_2')}</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <img
                  src={Image06Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subTitle04}>{t('O_que_fazemos.indicator_3_Number')}</div>
                <div className={styles.TextDefaultDivision04}>
                  {t('O_que_fazemos.indicator_3_Description_1')}
                </div>
                <div className={styles.Text03Division04}>{t('O_que_fazemos.indicator_3_Description_2')}</div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <img
                  src={Image02Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subTitle04}>{t('O_que_fazemos.indicator_4_Number')}</div>
                <div className="flex-row d-flex text-center">
                  <div className={styles.Text04Division04}>{t('O_que_fazemos.indicator_4_Description_1')}</div>
                  <div className={styles.TextDefaultDivision04}>{t('O_que_fazemos.indicator_4_Description_2')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.division05}>
        <div className={`masthead ${styles.division05ColorTop}`}>
          <div className="container px-5">
            <div className={styles.title05}>
              {t('O_que_fazemos.title5')}
            </div>
            <div className={styles.subTitle05}>{t('O_que_fazemos.subtitle5')}</div>
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
              <div className="me-lg-3 mb-4 mb-lg-0" onClick={handleRegist}>
                <Button name={t('O_que_fazemos.button_cadastre_agora')} typeButton="primary" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
