import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "../Awards/Awards.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function Awards() {
  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate("/revisar-concluir");
  };

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={7}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Premiações</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <div className={`form-label ${styles.infoTitle}`}>
                  Sua inscrição será analisada automaticamente para prêmios de
                  entrada, bolsas de estudo e subsídios baseados em sua
                  nacionalidade e/ou realizações acadêmicas anteriores. Os
                  candidatos só têm direito a receber um prémio ou bolsa. Os
                  candidatos que atenderem a várias qualificações receberão o
                  prêmio ou bolsa de estudos que lhes proporcionar o maior valor
                  monetário.
                </div>

                <div className={`form-label ${styles.infoTitle}`}>
                  Se admitido no local de escolha, você receberá uma carta de
                  Confirmação de Prêmio junto com sua carta de oferta em seu
                  programa.
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Com base na sua inscrição, você também pode ser considerado
                    para os seguintes prêmios:
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Você gostaria de se candidatar?
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${styles.radioAddress}`}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className={`form-check-label ${styles.textLabel}`}
                      htmlFor="flexRadioDefault1"
                    >
                      Sim
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${styles.radioAddress}`}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      // checked
                    />
                    <label
                      className={`form-check-label ${styles.textLabel}`}
                      htmlFor="flexRadioDefault2"
                    >
                      Não
                    </label>
                  </div>
                </div>

                <br />
                <div className="col-md-12" onClick={() => handleNextScreen()}>
                  <Button name="Próxima etapa" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
