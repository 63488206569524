export const TRANSLATIONS_EN = {
  menu: {
    menu1: 'What We Do',
    menu2: 'How It Works',
    menu3: 'Become an Affiliate',
    menu4: 'Become a Partner',
    menu5: 'FAQs',
    menu6: 'Blog',
    button_cadastre_agora: 'Sign up',
    button_entrar: 'Log In',
  },
  home: {
    title: 'Everything in One Place',
    subTitle: 'Your exchange platform in Canada with a reward program',
    button_o_que_fazemos: 'What We Do',
    button_cadastre_agora: 'Register Now',
    title2: 'Study wherever you want in Canada',
    brand1: 'Your',
    brand2: 'Brand',
    brand3: 'HERE',
    title3_1: 'We are the complete solution',
    title3_2: ': courses, visas, housing,',
    title3_3: 'certified translations, health insurance, and much more.',
    subtitle3_1: 'Here you find everything online to organize your study trip to Canada.',
    subtitle3_2: 'By enrolling through the platform and accessing other services, you earn loyalty points.',
    subtitle3_3: 'Each point returns to you as cashback.',
    title4: 'Student',
    subtitle4: 'We have partnerships with educational institutions across Canada. We offer a complete experience for you to organize your exchange.',
    button_pesquise_outros_servicos: 'Search other services',
    button_pesquise_onde_estudar: 'Search where to study',
    title5: 'Real Cashback',
    subtitle5: 'In addition to charging no fees, you also earn points that turn into cashback. Every registration and service you contract with us generates points that make your exchange affordable.',
    title6: 'Become an Affiliate',
    subtitle6_1: 'Do you like our solution and want to earn extra income?',
    subtitle6_2: 'Refer students to our platform. ',
    subtitle6_3: 'The more students you refer who become students in Canada,',
    subtitle6_4: 'the more you will earn.',
    button_entenda_como: 'Understand How',
    title7: 'Become a Partner',
    subtitle7_1: 'What do you think about your school or company becoming part of Next',
    subtitle7_2: ' Intake? Register on our platform and attract many more',
    subtitle7_3: ' students and clients.',
    title8: 'Testimonials',
    depoimento1: 'My wife and I are grateful for having relied on the help of Canada Without Borders to assist us with our “Canada plan”. All the support and transparency were crucial for our move to Vancouver in just 9 months. If you are thinking of moving to Canada, I highly recommend taking this initiative now with Canada Without Borders!! Thank you, Emerson and Tati.',
    depoimento1_location: 'Vancouver, Canada',
    depoimento2: 'I have been a client of Canada Without Borders since 2021 and am very satisfied with the quality of services they offer. My experience with them has been simply excellent. I contracted various services, including obtaining my student visa (in 2021) and finding the ideal university for the course I wished to study (in 2022). Throughout the process, Tati always provided all the detailed information and explanations...',
    depoimento2_location: 'Vancouver, Canada',
    depoimento3: "I had not yet found genuine people to talk about a dream project. I stumbled upon Canada Without Borders by chance and it was the best choice I made. The only company that valued my goal at all times. We achieved my objective together, and I am immensely grateful to Emerson, Tati, Jaqueline, William, and others who I know work hard. All are extremely attentive, transparent, and of great...",
    depoimento3_location: 'Vancouver, Canada',
    depoimento4: "The service from the entire team (especially Emerson and Tati) is excellent. They are very attentive and always respond promptly. They assisted my family in all stages of the process (ideal place to live according to our goals, visas, permits, airfare, insurance, daughter's school, MSP, university, partner recommendations, etc.). They are extremely reliable and competent. I highly recommend the company to anyone...",
    depoimento4_location: 'Vancouver, Canada',
    depoimento5: 'I highly recommend the work of Canada Without Borders because through the provision of a serious service (without deception or stalling), with transparency and great attention to detail, we overturned a visa denial for Canada in 2019. My family and I will always be grateful for having our process looked at so carefully, for the accurate and direct guidance. You guys are top-notch.',
    depoimento5_location: 'Vancouver, Canada',
    title9: 'Everything in one place',
    subtitle9: 'Come to Next Intake',
  },
  O_que_fazemos: {
    title1: 'We Have a Dream',
    subtitle1: 'We want more people like you studying in Canada',
    button_o_que_fazemos: 'What We Do',
    button_cadastre_agora: 'Register Now',
    button_como_funciona: 'How it works',
    title2: 'What we do',
    title2_1: 'We are a platform that offers exchanges that fit your budget',
    title2_1_subtitle: 'We take care of all the stages of organizing a study trip, connecting you to language schools, colleges, universities, and service providers. You enroll and enjoy this period in Canada to the fullest and in the best way.',
    title2_2: 'We offer real cashback through a rewards program',
    title2_2_subtitle: 'Our tool was designed with a unique feature: it operates with a loyalty and cashback points program that reduces the total investment in an exchange. This idea was developed to help many more people realize the dream of studying abroad.',
    title2_3: 'We speak your language while you still improve your English',
    title2_3_subtitle: 'All the content presented, including communication with our team, is entirely in Portuguese. This is valuable, especially for students who do not yet master English, offering more comfort to our users.',
    title2_4: 'We are a Canadian company operating in several countries',
    title2_4_subtitle: 'The platform was created to facilitate access to courses and exchange services in Canada for Latin students. In 2020, we founded the company in Canada and defined all the programming of Next Intake\'s functionalities.',
    title3: 'We are the complete solution',
    title3_1: ': courses, visas, housing,',
    title3_2: 'certified translations, health insurance, and much more.',
    subtitle3: 'Here you find everything online to organize your study trip to Canada.',
    subtitle3_1: 'By enrolling through the platform and accessing other services, you earn loyalty points.',
    subtitle3_2: 'Each point returns to you as cashback.',
    title4: 'Our goals until 2026',
    indicator_1_Number: '+4,000',
    indicator_1_Description_1: 'assisted',
    indicator_1_Description_2: 'students',
    indicator_2_Number: '+500',
    indicator_2_Description_1: 'partner',
    indicator_2_Description_2: 'schools',
    indicator_3_Number: '+100',
    indicator_3_Description_1: 'service',
    indicator_3_Description_2: 'providers',
    indicator_4_Number: '+1 million',
    indicator_4_Description_1: 'points',
    indicator_4_Description_2: 'offered',
    title5: 'Be Part of This Dream Too',
    subtitle5: 'Come to Next Intake',
  },
  Como_funciona: {
    title1: 'Understand How It Works',
    subtitle1: 'Learn about the Next Intake exchange platform and the cashback rewards program',
    button_o_que_fazemos: 'What We Do',
    button_cadastre_agora: 'Register Now',
    button_como_funciona: 'How It Works',
    title2: 'Understand How It Works',
    indicator_1_Number: '1st step',
    indicator_1_Description_1: 'Register',
    indicator_1_Description_2: 'on the',
    indicator_1_Description_3: 'platform',
    indicator_2_Number: '2nd step',
    indicator_2_Description_1: 'Choose where',
    indicator_2_Description_2: 'to study',
    indicator_3_Number: '3rd step',
    indicator_3_Description_1: 'Contract other',
    indicator_3_Description_2: 'services',
    indicator_4_Number: '4th step',
    indicator_4_Description_1: 'Accumulate',
    indicator_4_Description_2: 'points',
    title3: 'We are the complete solution',
    title3_1: ': courses, visas, housing,',
    title3_2: 'certified translations, health insurance, and much more.',
    subtitle3: 'Here you find everything online to organize your study trip to Canada. ',
    subtitle3_1: 'By enrolling through the platform and accessing other services,',
    subtitle3_2: 'you earn loyalty points.',
    title4: 'With us, you earn points and cashback',
    subtitle4: 'In addition to not charging any fees, you also earn points that turn into cashback. Every registration and service you contract with us generates points that make your exchange affordable.',
    title5: 'Be Part of This Dream Too',
    subtitle5: 'Come to Next Intake'
  },
  afiliado: {
    title1: 'Become an Affiliate',
    subtitle1: 'Every time you refer someone who becomes a student, you earn',
    button_o_que_fazemos: 'What We Do',
    button_cadastre_agora: 'Register Now',
    button_como_funciona: 'How It Works',
    title2: 'See How to Become an Affiliate',
    indicator_1_Number: '1st step',
    indicator_1_Description_1: 'Register',
    indicator_1_Description_2: 'on the',
    indicator_1_Description_3: 'platform',
    indicator_2_Number: '2nd step',
    indicator_2_Description_1: 'Refer students',
    indicator_2_Description_2: 'via',
    indicator_2_Description_3: ' an exclusive link',
    indicator_3_Number: '3rd step',
    indicator_3_Description_1: 'You get a bonus ',
    indicator_3_Description_2: 'when the student arrives in Canada',
    indicator_4_Number: '4th step',
    indicator_4_Description_1: 'Redeem your bonus',
    indicator_4_Description_2: 'or save more',
    title3: 'We are the complete solution',
    title3_1: ': courses, visas, housing,',
    title3_2: 'certified translations, health insurance, and much more.',
    subtitle3: 'Here you find everything online to organize your study trip to Canada.',
    subtitle3_1: 'By enrolling through the platform and accessing other services, you earn loyalty points.',
    subtitle3_2: 'Each point returns to you as cashback.',
    title4: 'We share our profits with you so that everyone wins',
    subtitle4: 'In our system, you accumulate points through student referrals to Next Intake. These points are converted into cashback when the students arrive in Canada. Our mission is to help more people achieve their dream of studying abroad.',
    title5: 'Promote This Opportunity to More Students',
    subtitle5: 'Come to Next Intake',
  },
  parceiro: {
    title1: 'Become a Partner',
    subtitle1: 'Our platform is the bridge between Canadian schools and Brazilian students',
    button_o_que_fazemos: 'What We Do',
    button_cadastre_agora: 'Register Now',
    button_como_funciona: 'How It Works',
    button_cadastre_escola: 'Register Your school',
    button_cadastre_empresa: 'Register Your company',
    title2: 'How we work',
    card_title1: 'Our Mission',
    card_title1_description: 'We developed Next Intake to offer more Brazilian students the opportunity to study in Canada.',
    card_title2: 'High Quality',
    card_title2_description: 'To save time and effort for your institution, we only start the application process after a thorough review of the documents.',
    card_title3: 'How We Do It',
    card_title3_description: 'Our team supports the student at every step of the preparation for the exchange through the resources offered on the platform.',
    title3: 'We are the complete solution',
    title3_1: ': courses, visas, housing,',
    title3_2: 'certified translations, health insurance, and much more.',
    subtitle3: 'Here you find everything online to organize your study trip to Canada. ',
    subtitle3_1: 'By enrolling through the platform and accessing other services, you earn loyalty points. ',
    subtitle3_2: ' Each point returns to you as cashback.',
    title4: 'How We Promote Your Brand',
    subtitle4: 'At Next Intake, the page of your institution or service provider is presented with information that highlights your differential in the educational market.',
    title5: 'We are different',
    title5_1: 'The future online is now.',
    subtitle5: 'With this in mind, we offer a complete virtual exchange organization experience on our platform. Our ',
    subtitle5_1: 'unique rewards program',
    subtitle5_2: 'encourages the student to complete all the steps at Next Intake with the support of our team and our partners.',
    title6: 'Be Seen and Attract More Students',
    subtitle6: 'Come to Next Intake'    
  },
  questions: {
    title1: 'Frequently Asked Questions',
    subtitle1: 'Clear your doubts about Next Intake here',
    title2: 'About the Next Intake platform',
    question1: 'What is',
    answers1: 'Next Intake is an educational program search platform that assists international students in planning and executing all the preparatory tasks for their study journeys in Canada, providing support in the native language, as well as a unique rewards program with cashback.',
    question2: 'What study options do I find on',
    answers2: "At Next Intake, you find language courses, high school programs, undergraduate and postgraduate programs at colleges (equivalent to faculties), as well as bachelor's and master's degrees at Canadian universities.",    
    question3: 'How can',
    question3_1: 'help me study in Canada?',
    answers3: 'Next Intake simplifies the study process in Canada, offering services such as enrollment in educational programs, organization of accommodation, visa assistance, document translations, and much more.',
    question4: 'Who can use',
    answers4: 'In addition to serving international students, Next Intake has an affiliate program. Affiliates can refer students to the platform and be compensated when these students arrive in Canada.',
    question5: 'What are the main benefits of using',
    answers5: 'The main benefits include access to a wide network of Canadian educational institutions and service providers, personalized assistance at every step of the process, support offered in Portuguese and Spanish, and a rewards program with cashback.',
    question6: 'How does the cashback reward program work?',
    answers6: 'Our rewards program allows students to accumulate points by using our services and these points can be converted into cashback once you are in Canada.',
    question7: 'Does',
    question7_1: 'provide support throughout the enrollment process?',
    answers7: 'Yes, we offer complete support to guide you in searching for study programs in Canada and organizing documents to enroll according to the school\'s requirements.',    
    question8: 'What services does',
    question8_1: 'offer besides enrollment in educational programs?',
    answers8: 'Beyond enrollment, we offer services like document translation, visa application assistance, housing search, health insurance, mobile phone services, banking services, and much more.',
    question9: 'Does',
    question9_1: 'charge any fees to students?',
    answers9: 'No, our services are free for students. Next Intake is compensated through commissions paid by educational institutions and service providers listed on our platform.',    
    question10: 'How do I start using',
    answers10: 'Simply register on our website and start exploring the educational program options and services. Our team will be available to guide you through each step of the process.',
    question11: 'How does',
    question11_1: 'select partner institutions?',
    answers11: 'We select our partner institutions based on their reputation, the quality of education, and their ability to provide adequate support to international students. We only work with institutions that share our commitment to educational excellence.',
    question12: 'What are the working hours of',
    answers12: 'Next Intake operates 24 hours a day, seven days a week. During your enrollment process, you interact with our team of experts according to the business hours of your region.',
    card1_title: 'Location',
    card1_description: 'Vancouver, Canada',
    card2_title: 'Email',
    card2_description: 'support@nextintake.com',
    card3_title: 'Phone',
    card3_description: '+1 (716) 220 8658',
    card4_title: 'WhatsApp',
    card4_description: '+1 (716) 220 8658',
    form_title: 'Send a message',
    form_input1_label: 'Full Name',
    form_input1_placeholder: 'First Last',
    form_input2_label: 'Email',
    form_input2_placeholder: 'your@email.com',
    form_input3_label: 'Subject',
    form_input3_option1: 'Select a Subject',
    form_input3_option2: 'Course and Enrollment Information',
    form_input3_option3: 'Visa Questions',
    form_input3_option4: 'Service Provider Questions',
    form_input3_option5: 'Technical Support',
    form_input3_option6: 'Other Subject',
    form_input4_label: 'Description',
    form_input4_placeholder: 'Provide more details about the purpose of your contact...',
    button_send_message: 'Send message',
    button_send_message_1: 'Please wait',
    button_send_fechar: 'Please wait',
  },
  blog: {
    title: 'Next Intake Blog',
    title2: 'Highlight',
    title3: 'More popular',
    noPosts: '- No posts registered -',
    loading: 'Loading...',
    btnBuscar: 'Find',
    placeholder: 'Type here what you are looking for',
    title4: 'Do you like what you see here?',
    title5: 'Subscribe to our newsletter and receive new content by email',
    input1: 'Full name',
    input1Placeholder: 'Name surname',
    input2: 'Email',
    input2Placeholder: 'meu@email.com',
    checkbox: 'I would like to receive new content from the Next Intake Blog',
    btnSend: 'I want to receive',
  } 
};
