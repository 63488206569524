import { useEffect } from "react";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "../TermsConditions/TermsConditions.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function TermsConditions() {
  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={9}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Termos e condições</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <ul className={`form-label ${styles.infoTitle}`}>
                  <li>
                    Aqui deve aparecer as perguntas e respostas preenchidas
                    pelos usuários. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.
                  </li>{" "}
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </li>
                </ul>

                <div className="col-md-12 form-check">
                  <input
                    className={`form-check-input ${styles.formCheck}`}
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Li, entendi e concordo com todas as declarações acima.
                  </label>
                </div>

                <br />
                <div className="col-md-12">
                  <Button name="Finalizar cadastro" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
