import { Router } from "./Routes/router";
import i18n from "i18next";
import './translations/i18n'
import { useEffect } from "react";


export function App() {
  
  useEffect(() => {
    i18n.changeLanguage('pt-BR')
    // i18n.changeLanguage('en-US')
    // i18n.changeLanguage('es')
  }, [])


  return <Router />;
}
