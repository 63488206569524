import styles from "../Home/Home.module.scss";
import Image05 from "../../../Assets/images/image05.png";
import Image06 from "../../../Assets/images/image06.png";
import Image07 from "../../../Assets/images/image07.png";

export function ConteinerImage({ item }: any) {
  const handleDragStart = (e: any) => e.preventDefault();
  
  return (
    <div className={`card px-4 align-content-center ${styles.carouselHome08}`}>
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className={`card-text ${styles.image08}`}>
            <img
              className="icon-circle"
              src={item.avatar}
              onDragStart={handleDragStart}
              role="presentation"
              alt="Depoimentos"
            />
          </div>
          <div className={styles.containerLeft08}>
            <div className={`${styles.title08}`}>{item.name}</div>
            <div className={`${styles.subTitle08}`}>{item.location}</div>
            <div className={styles.containerStar08}>
              <img
                className="icon-circle"
                src={Image06}
                onDragStart={handleDragStart}
                role="presentation"
                alt="Depoimentos"
              />
              <img
                className="icon-circle"
                src={Image06}
                onDragStart={handleDragStart}
                role="presentation"
                alt="Depoimentos"
              />
              <img
                className="icon-circle"
                src={Image06}
                onDragStart={handleDragStart}
                role="presentation"
                alt="Depoimentos"
              />
              <img
                className="icon-circle"
                src={Image06}
                onDragStart={handleDragStart}
                role="presentation"
                alt="Depoimentos"
              />
              <img
                className="icon-circle"
                src={Image06}
                onDragStart={handleDragStart}
                role="presentation"
                alt="Depoimentos"
              />
              {/* <img
                className="icon-circle"
                src={Image07}
                onDragStart={handleDragStart}
                role="presentation"
                alt="Depoimentos"
              /> */}
            </div>
          </div>
        </div>
        <div className={`${styles.description08}`}>
          {item.testimony}
        </div>
      </div>
    </div>
  );
}
