export const TRANSLATIONS_PT_BR = {
  menu: {
    menu1: 'O que fazemos',
    menu2: 'Como funciona',
    menu3: 'Seja um afiliado',
    menu4: 'Seja um parceiro',
    menu5: 'Perguntas',
    menu6: 'Blog',
    button_cadastre_agora: 'Cadastre-se',
    button_entrar: 'Entrar',
  },
  home: {
    title: 'Tudo em um só lugar',
    subTitle: 'Sua plataforma de intercâmbio no Canadá com programa de recompensa',
    button_o_que_fazemos: 'O que fazemos',
    button_cadastre_agora: 'Cadastre-se agora',
    title2: 'Estude onde quiser no Canadá',
    brand1: 'Sua',
    brand2: 'marca',
    brand3: 'AQUI',
    title3_1: 'Somos a solução completa',
    title3_2: ': cursos, visto, moradia,',
    title3_3: 'tradução juramentada, seguro saúde e muito mais.',
    subtitle3_1: 'Aqui você encontra tudo on-line para organizar sua viagem de estudo no Canadá. Ao se',
    subtitle3_2: 'matricular pela plataforma e acessar outros serviços, você ganha pontos de fidelidade.',
    subtitle3_3: 'Cada ponto volta para você em forma de cashback.',
    title4: 'Estudante',
    subtitle4: 'Temos parcerias com instituições de ensino em todo o Canadá. Oferecemos  uma experiência completa para você organizar o seu intercâmbio.',
    button_pesquise_outros_servicos: 'Pesquise outros serviços',
    button_pesquise_onde_estudar: 'Pesquise onde estudar',
    title5: 'Cashback de verdade',
    subtitle5: 'Além de não cobrarmos nenhuma taxa, você ainda ganha pontos que viram cashback. Cada matrícula feita e serviço contratado com a gente geram pontos que ajudam seu intercâmbio caber no seu bolso.',
    title6: 'Seja um afiliado',
    subtitle6_1: 'Gostou da nossa solução e quer ganhar uma renda extra?',
    subtitle6_2: 'Indique estudantes para a nossa plataforma.',
    subtitle6_3: 'Quanto mais estudantes indicados se tornarem alunos no Canadá,',
    subtitle6_4: 'mais você vai ganhar. ',
    button_entenda_como: 'Entenda como',
    title7: 'Seja um parceiro',
    subtitle7_1: 'O que você acha da sua escola ou empresa fazer parte da Next',
    subtitle7_2: ' Intake? Cadastre-se na nossa plataforma e atraia muito mais',
    subtitle7_3: ' estudantes e clientes.',
    title8: 'Depoimentos',
    depoimento1: 'Eu e a minha esposa somos gratos por ter contado com a ajuda da Canadá sem Fronteiras para nos ajudar no nosso “plano Canadá”. Todo suporte e transparência foram cruciais para nossa vinda para Vancouver em apenas 9 meses. Se está pensando em vir para o Canadá, super recomendo tomar essa iniciativa agora e com a Canadá sem Fronteiras!! Muito obrigado Emerson e Tati.',
    depoimento1_location: 'Vancouver, Canadá',
    depoimento2: 'Sou cliente da Canada sem Fronteiras desde 2021 e sou muito satisfeita com a qualidade dos serviços que eles oferecem. Minha experiência com eles tem sido simplesmente excelente. Contratei vários serviços, incluindo a obtenção do meu visto (em 2021) de estudante e a busca pela universidade ideal para o curso que desejava cursar (em 2022). Durante todo o processo, a Tati sempre forneceu todas as informações detalhadas e explicações...',
    depoimento2_location: 'Vancouver, Canadá',
    depoimento3: 'Eu não havia encontrado ainda pessoas verdadeiras para falar de um projeto dos sonhos. Encontrei a Canadá Sem Fronteiras por acaso e foi a melhor escolha que fiz. Única empresa que prezou a todo momento, pelo meu objetivo. Atingimos juntos meu objetivo, e agradeço imensamente ao Emerson, a Tati, a Jaqueline, William e outras pessoas que sei que trabalham duro. Todos super atenciosos, transparentes e de ótima...',
    depoimento3_location: 'Vancouver, Canadá',
    depoimento4: 'O atendimento de toda a equipe (em especial o Emerson e a Tati) é excelente. São muito atenciosos e respondem sempre prontamente. Auxiliaram a minha família em todas as etapas do processo (local ideal para morar de acordo com nossos objetivos, vistos, permits, aéreo, seguro, escola da filha, MSP, universidade, recomendações de parceiros, etc). São extremamente confiáveis e competentes. Recomendo muito a empresa para quem...' ,
    depoimento4_location: 'Vancouver, Canadá',
    depoimento5: 'Super recomendo o trabalho da Canadá Sem Fronteiras porque através da prestação de um serviço sério (sem enganação ou enrolação), com transparência e muita atenção aos detalhes, revertemos uma negativa de visto para O Canadá em 2019. Minha família e eu seremos sempre gratos por ter nosso processo olhado com tanto cuidado, pelas orientações acertivas e diretas. Vocês são nota miiiiil.',
    depoimento5_location: 'Vancouver, Canadá',
    title9: 'Tudo em um só lugar',
    subtitle9: 'Venha para a Next Intake',
  },
  O_que_fazemos: {
    title1: 'Nós temos um sonho',
    subtitle1: 'Queremos mais pessoas como você estudando no Canadá',
    button_o_que_fazemos: 'O que fazemos',
    button_cadastre_agora: 'Cadastre-se agora',
    button_como_funciona: 'Como funciona',
    title2: 'O que fazemos',
    title2_1: 'Somos uma plataforma que oferece intercâmbios que cabem no seu bolso',
    title2_1_subtitle: 'Cuidamos de todas as etapas de organização de uma viagem de estudo, conectando você a escolas de idiomas, colleges, universidades e provedores de serviços. Você faz sua matrícula e desfruta este período no Canadá ao máximo e da melhor forma.',
    title2_2: 'Oferecemos cashback de verdade por meio de um programa de recompensa',
    title2_2_subtitle: 'Nossa ferramenta foi projetada com um diferencial: operar com um programa de pontos de fidelidade e cashback que reduz o investimento total em um intercâmbio. Essa ideia foi desenvolvida para ajudar muito mais pessoas a realizar o sonho de estudar no exterior.',
    title2_3: 'Falamos a sua língua enquanto você ainda aprimora o inglês',
    title2_3_subtitle: 'Todo o conteúdo apresentado, além comunicação com nossa equipe é completamente em português. Isso é valioso, em especial, para estudantes que ainda não dominam o inglês, o que oferece mais conforto aos nossos usuários.',
    title2_4: 'Somos uma empresa canadense com operação em diversos países',
    title2_4_subtitle: 'A plataforma nasceu para facilitar o acesso à cursos e serviços de intercâmbio do Canadá por estudantes latinos. Em 2020, fundamos a empresa no Canadá e definimos toda a programação das funcionalidades da Next Intake.',
    title3: 'Somos a solução completa',
    title3_1: ': cursos, visto, moradia,',
    title3_2: 'tradução juramentada, seguro saúde e muito mais.',
    subtitle3: 'Aqui você encontra tudo on-line para organizar sua viagem de estudo no Canadá. Ao se ',
    subtitle3_1: 'matricular pela plataforma e acessar outros serviços, você ganha pontos de fidelidade. ',
    subtitle3_2: 'Cada ponto volta para você em forma de cashback.',
    title4: 'Nossas metas até 2026',
    indicator_1_Number: '+4 mil',
    indicator_1_Description_1: 'Estudantes',
    indicator_1_Description_2: 'assistidos',
    indicator_2_Number: '+500',
    indicator_2_Description_1: 'Escolas',
    indicator_2_Description_2: 'parceiras',
    indicator_3_Number: '+100',
    indicator_3_Description_1: 'Provedores de',
    indicator_3_Description_2: 'serviços',
    indicator_4_Number: '+1 milhão',
    indicator_4_Description_1: 'Pontos',
    indicator_4_Description_2: 'oferecidos',
    title5: 'Faça parte desse sonho você também',
    subtitle5: 'Venha para a Next Intake',
  },
  Como_funciona: {
    title1: 'Entenda como funciona',
    subtitle1: 'Conheça a plataforma de intercâmbio Next Intake e o programa recompensa com cashback',
    button_o_que_fazemos: 'O que fazemos',
    button_cadastre_agora: 'Cadastre-se agora',
    button_como_funciona: 'Como funciona',
    title2: 'Entenda como funciona',
    indicator_1_Number: '1º passo',
    indicator_1_Description_1: 'Cadastre-se',
    indicator_1_Description_2: 'na',
    indicator_1_Description_3: 'plataforma',
    indicator_2_Number: '2º passo',
    indicator_2_Description_1: 'Escolha onde',
    indicator_2_Description_2: 'estudar',
    indicator_3_Number: '3º passo',
    indicator_3_Description_1: 'Contrate outros',
    indicator_3_Description_2: 'serviços',
    indicator_4_Number: '4º passo',
    indicator_4_Description_1: 'Acumule',
    indicator_4_Description_2: 'pontos',
    title3: 'Somos a solução completa',
    title3_1: ': cursos, visto, moradia,',
    title3_2: 'tradução juramentada, seguro saúde e muito mais.',
    subtitle3: 'Aqui o aluno encontra tudo on-line para organizar sua viagem de estudo. Ao se',
    subtitle3_1: 'matricular pela plataforma, ele começa a ganhar pontos que geram descontos para a',
    subtitle3_2: 'compra de outros serviços.',
    title4: 'Com a gente, você ganha pontos e cashback',
    subtitle4: 'Além de não cobrarmos nenhuma taxa, você ainda ganha pontos que viram cashback. Cada matrícula feita e serviço contratado com a gente geram pontos que ajudam seu intercâmbio caber no seu bolso.',
    title5: 'Faça parte desse sonho você também',
    subtitle5: 'Venha para a Next Intake'
  },
  afiliado: {
    title1: 'Seja um afiliado',
    subtitle1: 'Toda vez que você indica alguém que se torna aluno, você ganha',
    button_o_que_fazemos: 'O que fazemos',
    button_cadastre_agora: 'Cadastre-se agora',
    button_como_funciona: 'Como funciona',
    title2: 'Veja como se tornar um afiliado',
    indicator_1_Number: '1º passo',
    indicator_1_Description_1: 'Cadastre-se',
    indicator_1_Description_2: 'na',
    indicator_1_Description_3: 'plataforma',
    indicator_2_Number: '2º passo',
    indicator_2_Description_1: 'Indique alunos',
    indicator_2_Description_2: 'via',
    indicator_2_Description_3: 'link exclusivo',
    indicator_3_Number: '3º passo',
    indicator_3_Description_1: 'Você é bonificado',
    indicator_3_Description_2: 'quando o aluno chega no Canadá',
    indicator_4_Number: '4º passo',
    indicator_4_Description_1: 'Resgate seu bônus',
    indicator_4_Description_2: 'ou junte mais',
    title3: 'Somos a solução completa',
    title3_1: ': cursos, visto, moradia,',
    title3_2: 'tradução juramentada, seguro saúde e muito mais.',
    subtitle3: 'Aqui você encontra tudo on-line para organizar sua viagem de estudo no Canadá. Ao se ',
    subtitle3_1: 'matricular pela plataforma e acessar outros serviços, você ganha pontos de fidelidade. ',
    subtitle3_2: 'Cada ponto volta para você em forma de cashback.',
    title4: 'Dividimos nosso lucro com você para que todos ganhem',
    subtitle4: 'No nosso sistema, você acumula pontos por meio de indicações de estudantes para a Next Intake. Esses pontos são convertidos em cashback quando os estudantes chegam no Canadá. A nossa missão é ajudar mais pessoas no seu sonho de estudar no exterior.',
    title5: 'Divulgue essa oportunidade para mais estudantes',
    subtitle5: 'Venha para a Next Intake',
  },
  parceiro: {
    title1: 'Seja um parceiro',
    subtitle1: 'Nossa plataforma é a ponte entre escolas canadenses e estudantes brasileiros',
    button_o_que_fazemos: 'O que fazemos',
    button_cadastre_agora: 'Cadastre-se agora',
    button_como_funciona: 'Como funciona',
    button_cadastre_escola: 'Cadastre sua escola',
    button_cadastre_empresa: 'Cadastre sua empresa',
    title2: 'Como nós trabalhamos',
    card_title1: 'Nossa missão',
    card_title1_description: 'Nos desenvolvemos a Next Intake para oferecer a mais estudantes brasileiros a oportunidade de estudar no Canadá.',
    card_title2: 'Alta qualidade',
    card_title2_description: 'Para poupar tempo e esforço da sua instituição, nós só iniciamos o processo de aplicação após minuciosa revisão dos documentos.',
    card_title3: 'Como fazemos',
    card_title3_description: 'Nossa equipe apoia o estudante em cada etapa da preparação do intercâmbio por meio dos recursos oferecido na plataforma.',
    title3: 'Somos a solução completa',
    title3_1: ': cursos, visto, moradia,',
    title3_2: 'tradução juramentada, seguro saúde e muito mais.',
    subtitle3: 'Aqui o aluno encontra tudo on-line para organizar sua viagem de estudo. Ao se',
    subtitle3_1: 'matricular pela plataforma, ele começa a ganhar pontos que geram descontos para a',
    subtitle3_2: 'compra de outros serviços.',
    title4: 'Como divulgamos sua marca',
    subtitle4: 'Na Next Intake, a página de sua instituição ou empresa provedora de serviços é apresentada com as informações que destacam o seu diferencial no mercado educacional.',
    title5: 'Nós somos diferentes',
    title5_1: 'O futuro on-line é agora.',
    subtitle5: 'Pensando nisso, oferecemos uma experiência virtual completa de organização de intercâmbio em nossa plataforma. Nosso ',
    subtitle5_1: 'programa único de recompensa',
    subtitle5_2: 'incentiva o estudante a concluir todas as etapas na Next Intake com o apoio de nossa equipe e nossos parceiros.',
    title6: 'Seja visto e atraia mais estudantes',
    subtitle6: 'Venha para a Next Intake'    
  },
  questions: {
    title1: 'Perguntas frequentes',
    subtitle1: 'Tire aqui as suas dúvidas sobre a Next Intake',
    title2: 'Sobre a plataforma Next Intake',
    question1: 'O que é a',
    answers1: 'Next Intake é uma plataforma de busca de programas educacionais que auxilia estudantes internacionais a planejar e executar todas as tarefas de preparação de suas jornadas de estudo no Canadá, oferecendo suporte na língua nativa, além  de um programa de recompensa único com cashback.',
    question2: 'Quais opções de estudo encontro na',
    answers2: 'Na Next Intake você encontra cursos de idioma, programas de high school (Ensino Médio), graduação e pós-graduação em colleges (o mesmo que faculdades), além de bacharelados e mestrados em universidades canadenses.',    
    question3: 'Como a ',
    question3_1: 'pode me ajudar a estudar no Canadá?',
    answers3: 'A Next Intake simplifica o processo de estudo no Canadá, oferecendo serviços como inscrição em programas educacionais, organização de acomodação, assistência para vistos, traduções de documentos, e muito mais.',
    question4: 'Quem pode usar a',
    answers4: 'Além de atender estudantes internacionais, a Next Intake tem um programa de afiliados. Os afiliados podem indicar estudantes para a plataforma e ser remunerados quando esses estudantes chegam no Canadá.',
    question5: 'Quais são os principais benefícios de usar a', 
    answers5: 'Os principais benefícios incluem acesso a uma rede ampla de instituições educacionais canadenses e provedores de serviços, assistência personalizada em todas as etapas do processo, suporte oferecido em português e espanhol, além de um programa de recompensa com cashback.',
    question6: 'Como funciona o programa de recompensas em cashback?',
    answers6: 'Nosso programa de recompensas permite que os estudantes acumulem pontos ao usar nossos serviços e esses pontos podem ser convertidos em cashback no momento em que você já estiver no Canadá.',
    question7: 'A',
    question7_1: 'suporte durante todo o processo de matrícula?',
    answers7: 'Sim, oferecemos suporte completo para te guiar na  busca por programas de estudo no Canadá e na organização de documentos para realizar a matrícula de acordo com as exigências da escola.',    
    question8: 'Quais serviços a',
    question8_1: 'oferece além da matrícula em programas educacionais?',
    answers8: 'Além da matrícula, oferecemos serviços como tradução de documentos, assessoria para pedido de visto, busca de moradia, seguro saúde, telefonia móvel, serviços bancários e muito mais.',
    question9: 'A',
    question9_1: 'cobra alguma taxa dos estudantes?',
    answers9: 'Não, nossos serviços são gratuitos para os estudantes. A Next Intake é remunerada por comissões pagas pelas instituições educacionais e provedores de serviços que estão listados na nossa plataforma.',    
    question10: 'Como faço para começar a usar a ',
    answers10: 'Basta se inscrever em nosso site e começar a explorar as opções de programas educacionais e serviços. Nossa equipe estará disponível para te guiar em cada etapa do processo.',
    question11: 'Como a',
    question11_1: 'seleciona as instituições parceiras? ',
    answers11: 'Selecionamos nossas instituições parceiras com base em sua reputação, qualidade de ensino e a capacidade de oferecer suporte adequado aos estudantes internacionais. Trabalhamos apenas com instituições que compartilham nosso compromisso com a excelência educacional.',
    question12: 'Qual é o horário de atendimento da ',
    answers12: 'A Next Intake funciona 24 horas por dia, sete dias por semana. Durante o seu processo de matrícula, você interage com o nosso time de especialistas de acordo com o horário comercial da sua região.',
    card1_title: 'Localização',
    card1_description: 'Vancouver, Canadá',
    card2_title: 'E-mail',
    card2_description: 'support@nextintake.com',
    card3_title: 'Telefone',
    card3_description: '+1 (716) 220 8658',
    card4_title: 'WhatsApp',
    card4_description: '+1 (716) 220 8658',
    form_title: 'Envie uma mensagem',
    form_input1_label: 'Nome completo',
    form_input1_placeholder: 'Nome Sobrenome',
    form_input2_label: 'E-mail',
    form_input2_placeholder: 'meu@email.com',
    form_input3_label: 'Assunto',
    form_input3_option1: 'Selecione um Assunto',
    form_input3_option2: 'Informações sobre Cursos e Matrícula',
    form_input3_option3: 'Dúvidas sobre Vistos',
    form_input3_option4: 'Dúvidas sobre Provedores de Serviços',
    form_input3_option5: 'Suporte Técnico',
    form_input3_option6: 'Outro Assunto',
    form_input4_label: 'Descrição',
    form_input4_placeholder: 'Forneça mais detalhes sobre o propósito do seu contato...',
    button_send_message: 'Enviar mensagem',
    button_send_message_1: 'Aguarde',
    button_send_fechar: 'Aguarde',
  },
  blog: {
    title: 'Blog da Next Intake',
    title2: 'Destaque',
    title3: 'Mais populares',
    noPosts: '- Sem postagens cadastradas -',
    loading: 'Carregando...',
    btnBuscar: 'Buscar',
    placeholder: 'Digite aqui o que procura',
    title4: 'Gostou do que está vendo por aqui?',
    title5: 'Assine nossa newsletter e receba novos conteúdos por e-mail',
    input1: 'Nome completo',
    input1Placeholder: 'Nome Sobrenome',
    input2: 'Email',
    input2Placeholder: 'meu@email.com',
    checkbox: 'Desejo receber os novos conteúdos do Blog da Next Intake',
    btnSend: 'Quero receber',
  } 
};
