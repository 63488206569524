import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";
import styles from "../Dashboard/Dashboard.module.scss";
import image13 from "../../../Assets/images/icon/image13.png";
import image14 from "../../../Assets/images/icon/image14.png";
import image15 from "../../../Assets/images/icon/image15.png";
import image16 from "../../../Assets/images/icon/image16.png";

export function Dashboard() {
  const navigate = useNavigate();
  function handleNextScreen() {
    navigate("/informacao-programa");
  }
  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <div className="container px-5 col-12">
          <div className={styles.title01}>
            <span>Boas-vindas à Next Intake</span>
          </div>

          <div className={styles.section02}>
            <div className={styles.container}>
              <img
                className={`app-badge ${styles.imageContainer}`}
                src={image13}
                alt="..."
              />
              <div className={styles.containerForText}>
                <span className={styles.textContainer}>Cursos</span>
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.container}>
              <img
                className={`app-badge ${styles.imageContainer}`}
                src={image14}
                alt="..."
              />
              <div className={styles.containerForText}>
                <span className={styles.textContainer}>Escolas</span>
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.container}>
              <img
                className={`app-badge ${styles.imageContainer}`}
                src={image15}
                alt="..."
              />
              <div className={styles.containerForText}>
                <span className={styles.textContainer}>Serviços</span>
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.container} onClick={handleNextScreen}>
              <img
                className={`app-badge ${styles.imageContainer}`}
                src={image16}
                alt="..."
              />
              <div className={styles.containerForText}>
                <span className={styles.textContainer}>Completar cadastro</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
