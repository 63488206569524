import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MenuHeader } from "../../../Components/Public/Header/MenuHeader";
import "react-alice-carousel/lib/alice-carousel.css";
import styles from "../Login/Login.module.scss";
import { Button } from "../../../Components/Button";
import Image11 from "../../../Assets/images/icon/image11.png";
import Image12 from "../../../Assets/images/icon/image12.png";

export function Login() {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/boas-vindas");
  };

  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/login`
    // navigate("/");
  }, []);

  const goTo = () => {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/login`
  }

  return (
    <div id="page-top">
      {/* <MenuHeader /> */}
      <section className={`masthead ${styles.section01}`}>
        {/* <div className="container px-5 col-6 col-sm-4">
          <div className={styles.title01}>
            <span>Boas-vindas à Next Intake</span>
          </div>
          <div className={styles.subTitle01}>
            Entre usando a sua conta de rede social ou e-mail
            <br />
            para continuar.
          </div>

          <div className={styles.section02}>
            <img
              className={`app-badge ${styles.imageSocialNetwork}`}
              src={Image12}
              alt="..."
            />
            <div className={styles.divider} />
            <img
              className={`app-badge ${styles.imageSocialNetwork}`}
              src={Image11}
              alt="..."
            />
          </div>

          <div className={styles.section03}>
            <div className={styles.lineDivider} />
            <span className={styles.textDivider}>ou</span>
            <div className={styles.lineDivider} />
          </div>

          <div className={styles.section04}>
            <form className="row g-3">
              <div className="col-md-12">
                <label
                  form="validationDefault02"
                  className={`form-label ${styles.textLabel}`}
                >
                  E-mail<span className={styles.asterisk}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault02"
                  placeholder="seuemail@email.com.br"
                  value=""
                  required
                />
              </div>
              <div className="col-md-12">
                <label
                  form="validationDefault02"
                  className={`form-label ${styles.textLabel}`}
                >
                  Senha<span className={styles.asterisk}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault02"
                  placeholder="Digite sua senha"
                  value=""
                  required
                />
              </div>
              <br />
              <div className={styles.section05}>
                <Button
                  name="Entrar"
                  onClick={handleLogin}
                  typeButton="primary"
                />
                <span className={styles.textForgotPassword}>
                  Esqueci a senha
                </span>
              </div>
              <div className={styles.section06}>
                <span className={styles.textIAmNotRegistered}>
                  Não sou cadastrado ainda
                </span>
              </div>
            </form>
          </div>
        </div> */}
      </section>
    </div>
  );
}
