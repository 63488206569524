import { useEffect, useState } from "react";
import { Header } from "../../../Components/Public/Header";
import styles from "../Questions/questions.module.scss";
import Image15 from "../../../Assets/images/image15.png";
import { Button } from "../../../Components/Button";
import Image27 from "../../../Assets/images/icon/image27.png";
import Image28 from "../../../Assets/images/icon/image28.png";
import Image29 from "../../../Assets/images/icon/image29.png";
import Image30 from "../../../Assets/images/icon/image30.png";
import "react-alice-carousel/lib/alice-carousel.css";
import { Footer } from "../../../Components/Public/Footer";
import axios from "axios";
import { useTranslation } from "react-i18next";

export function Questions() {
  const { t } = useTranslation()
  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const [statusMessage, setStatusMessage] = useState('')
  const [typeMessage, setTypeMessage] = useState('')

  const [disabled, setDisabled] = useState(false)

  const handleContact = async () => {
    setDisabled(true)

    if(
      name && name !== '' &&
      email && email !== '' &&
      subject && subject !== '' &&
      message && message !== ''
      ) {
        const data = {
          name, email, subject, message
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/contacts/`, data)

        if(res && res.status === 201) {
          setName('')
          setEmail('')
          setSubject('')
          setMessage('')
          setStatusMessage('Contato enviado com sucesso! Entraremos em contato em breve!')
          setTypeMessage('success')
          setDisabled(false)
        } else {
          setStatusMessage('Algo deu errado, tente novamente mais tarde!')
          setTypeMessage('error')
          setDisabled(false)
        }
      } else {
        setStatusMessage('Nome, email, assunto e descrição são campos obrigatórios!')
        setTypeMessage('warning')
        setDisabled(false)
      }
  }

  const closeMessage = () => {
    setStatusMessage('')
    setTypeMessage('')
  }

  return (
    <div id="page-top" className={styles.generalContainer}>
      <Header position={4} />
      <header className={`masthead ${styles.division01}`}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <div className={`${styles.title01}`}>{t('questions.title1')}</div>
                <div className={`${styles.subTitle01}`}>
                  {t('questions.subtitle1')}
                </div>
                <div
                  className={`d-flex flex-lg-row align-items-center ${styles.btnDivision01} ${styles.deviceDivision01}`}
                >
                  <div
                    className={`accordion ${styles.accordionItemHeader}`}
                    id="accordionExample"
                  >
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className={`masthead-device-mockup ${styles.imageDivision01}`}
              >
                <img src={Image15} alt="Imagem" />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* ************************************************************************* */}

      <section className={styles.division02}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className={styles.title02}>{t('questions.title2')}</div>

            <div className="col-lg-12">
              <div className="accordion" id="accordionExample">
                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {t('questions.question1')}
                      <span className={styles.boldText}>Next Intake</span>?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers1')}
                    </div>
                  </div>
                </div>
                
                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {t('questions.question2')}
                      {" "}
                      <span className={styles.boldText}>Next Intake</span>?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers2')}
                    </div>
                  </div>
                </div>

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {t('questions.question3')}{" "}<span className={styles.boldText}>Next Intake</span><span className={styles.marginRight} />{t('questions.question3_1')}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers3')}
                    </div>
                  </div>
                </div>

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {t('questions.question4')}{" "}<span className={styles.boldText}>Next Intake</span>?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers4')}
                    </div>
                  </div>
                </div>

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {t('questions.question5')}{" "}<span className={styles.boldText}>Next Intake</span>?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers5')}                       
                    </div>
                  </div>
                </div>     

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingTwelve">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwelve"
                      aria-expanded="false"
                      aria-controls="collapseTwelve"
                    >
                      {t('questions.question6')}
                    </button>
                  </h2>
                  <div
                    id="collapseTwelve"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwelve"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers6')}
                    </div>
                  </div>
                </div>   

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      {t('questions.question7')}{" "}<span className={styles.boldText}>Next Intake</span><span className={styles.marginRight} />{t('questions.question7_1')}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers7')}
                    </div>
                  </div>
                </div>      

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      {t('questions.question8')}{" "}<span className={styles.boldText}>Next Intake</span><span className={styles.marginRight} />{t('questions.question8_1')}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers8')}
                    </div>
                  </div>
                </div>   

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      {t('questions.question9')}{" "}<span className={styles.boldText}>Next Intake</span><span className={styles.marginRight} />{t('questions.question9_1')}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers9')}                      
                    </div>
                  </div>
                </div> 

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingNine">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >                      
                      {t('questions.question10')}{" "}<span className={styles.boldText}>Next Intake</span>?
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers10')}
                    </div>
                  </div>
                </div> 

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingten">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseten"
                      aria-expanded="false"
                      aria-controls="collapseten"
                    >                      
                      {t('questions.question11')}{" "}<span className={styles.boldText}>Next Intake</span><span className={styles.marginRight} />{t('questions.question11_1')}
                    </button>
                  </h2>
                  <div
                    id="collapseten"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingten"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers11')}
                    </div>
                  </div>
                </div>  

                <div className={`accordion-item ${styles.accordionItem}`}>
                  <h2 className="accordion-header" id="headingEleven">
                    <button
                      className={`accordion-button collapsed ${styles.accordionButton}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >                      
                      {t('questions.question12')}{" "}<span className={styles.boldText}>Next Intake</span>?
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {t('questions.answers12')}
                    </div>
                  </div>
                </div>                                                  

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ************************************************************************* */}

      <section className={styles.division03}>
        <div className={`masthead ${styles.division03ColorTop}`}>
          <div className="container px-5">
            <div className="row gx-5 justify-content-center align-items-center">
              <div className="col-lg-6" style={{ zIndex: 1 }}>
                <div
                  className="row gx-5 align-items-start"
                  style={{
                    zIndex: 1,
                    marginLeft: 40,
                    width: 600,
                  }}
                >
                  <div className="col-lg-6">
                    <div
                      className={`mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex ${styles.division3Rectangle}`}
                    >
                      <img src={Image27} alt="Imagem" />
                      <div className={styles.squareText}>{t('questions.card1_title')}</div>
                      <div className={styles.subSquareText}>
                        {t('questions.card1_description')}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className={`mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex ${styles.division3Rectangle}`}
                    >
                      <img src={Image28} alt="Imagem" />
                      <div className={styles.squareText}>{t('questions.card2_title')}</div>
                      <div className={styles.subSquareText}>
                        {t('questions.card2_description')}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div
                      className={`mt-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex ${styles.division3Rectangle}`}
                    >
                      <img src={Image29} alt="Imagem" />
                      <div className={styles.squareText}>{t('questions.card3_title')}</div>
                      <div className={styles.subSquareText}>
                        {t('questions.card3_description')}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className={`mt-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex ${styles.division3Rectangle}`}
                    >
                      <img src={Image30} alt="Imagem" />
                      <div className={styles.squareText}>{t('questions.card4_title')}</div>
                      <div className={styles.subSquareText}>
                        {t('questions.card4_description')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{
                  backgroundColor: "#fff",
                  padding: 70,
                  borderRadius: 8,
                  paddingTop: 190,
                  paddingBottom: 100,
                }}
              >
                <div className={styles.titleForm}>{t('questions.form_title')}</div>
                <form className={`row g-3 ${styles.form}`}>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      {t('questions.form_input1_label')}<span className={styles.asterisk}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      placeholder={t('questions.form_input1_placeholder')}
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      {t('questions.form_input2_label')}<span className={styles.asterisk}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      placeholder={t('questions.form_input2_placeholder')}
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      {t('questions.form_input3_label')}<span className={styles.asterisk}>*</span>
                    </label>
                    {/* <input
                      type="text"
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      placeholder="Dúvidas sobre a matrícula"
                      value={subject}
                      required
                      onChange={(e) => setSubject(e.target.value)}
                    /> */}
                    <select
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      value={subject}
                      required
                      onChange={(e) => setSubject(e.target.value)}
                    >
                      <option value={""} disabled>{t('questions.form_input3_option1')}</option>
                      <option value={"Informações sobre Cursos e Matrícula"}>{t('questions.form_input3_option2')}</option>
                      <option value={"Dúvidas sobre Vistos"}>{t('questions.form_input3_option3')}</option>
                      <option value={"Dúvidas sobre Provedores de Serviços"}>{t('questions.form_input3_option4')}</option>
                      <option value={"Suporte Técnico"}>{t('questions.form_input3_option5')}</option>
                      <option value={"Outro Assunto"}>{t('questions.form_input3_option6')}</option>
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      {t('questions.form_input4_label')}<span className={styles.asterisk}>*</span>
                    </label>
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      className={`form-control ${styles.textTextArea}`}
                      placeholder={t('questions.form_input4_placeholder')}
                    />
                  </div>
                  <br />
                  {statusMessage && statusMessage !== '' ?
                    <>
                      {typeMessage == 'success' ?
                        <div style={{ background: 'rgb(237, 247, 237)', fontSize: '0.8rem', padding: '8px', borderRadius: '4px', textAlign: 'center', color: 'rgb(30, 70, 32)' }}>{statusMessage} <span style={{ marginLeft: '8px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={closeMessage}>{t('questions.button_send_fechar')}</span></div>
                        : null
                      }
                      {typeMessage == 'warning' ?
                        <div style={{ background: 'rgb(255, 244, 229)', fontSize: '0.8rem', padding: '8px', borderRadius: '4px', textAlign: 'center', color: 'rgb(102, 60, 0)' }}>{statusMessage} <span style={{ marginLeft: '8px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={closeMessage}>{t('questions.button_send_fechar')}</span></div>
                        : null
                      }
                      {typeMessage == 'error' ?
                        <div style={{ background: 'rgb(253, 237, 237)', fontSize: '0.8rem', padding: '8px', borderRadius: '4px', textAlign: 'center', color: 'rgb(95, 33, 32)' }}>{statusMessage} <span style={{ marginLeft: '8px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={closeMessage}>{t('questions.button_send_fechar')}</span></div>
                        : null
                      }
                    </>
                  : null}
                  <div className={styles.section05}>
                    <Button
                      onClick={handleContact}
                      name={disabled ? t('questions.button_send_message_1') : t('questions.button_send_message')}
                      disabled={disabled}
                      // onClick={handleLogin}
                      typeButton="primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
