import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Public/Header";
import styles from "../Partner/Partner.module.scss";
import Image00 from "../../../Assets/images/image04.png";
import ImageCourse2 from "../../../Assets/images/course2.png";
import Image08 from "../../../Assets/images/affiliated.png";
import Image02Division02 from "../../../Assets/images/icon/image09.png";
import Image05Division02 from "../../../Assets/images/icon/image07.png";
import Image06Division02 from "../../../Assets/images/icon/image08.png";
import Image02 from "../../../Assets/images/image13.png";
import Image01 from "../../../Assets/images/image14.png";
import { Button } from "../../../Components/Button";
import "react-alice-carousel/lib/alice-carousel.css";
import { Footer } from "../../../Components/Public/Footer";
import { useTranslation } from "react-i18next";

export function Partner() {
  const { t } = useTranslation()
  const navigate = useNavigate();

  function handleRegist() {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/subscribe`
    // navigate("/cadastre-se");
  }

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header position={3} />
      <header className={`masthead ${styles.division01}`}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <div className={`${styles.title01}`}>{t('parceiro.title1')}</div>
                <div className={`${styles.subTitle01}`}>
                  {t('parceiro.subtitle1')}
                </div>
                <div
                  className={`d-flex flex-lg-row align-items-center ${styles.btnDivision01} ${styles.deviceDivision01}`}
                >
                  <div onClick={handleRegist}>
                    <Button name={t('parceiro.button_cadastre_agora')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img
                  src={Image00}
                  className={styles.imageDivision01}
                  alt="Imagem"
                />
              </div>
            </div>
            <div
              className={`d-flex flex-lg-row mt-3 justify-content-center align-items-center ${styles.deviceDivision01_02}`}
            >
              <div className="m-2" onClick={handleRegist}>
                <Button name={t('parceiro.button_cadastre_agora')} typeButton="primary" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className={styles.division02}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className={styles.title02}>{t('parceiro.title2')}</div>

            <div className="col-lg-4">
              <div
                className={`mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex ${styles.border01Division2}`}
              >
                <img
                  src={Image05Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subText02}>{t('parceiro.card_title1')}</div>
                <div className={styles.TextDefaultDivision02}>
                {t('parceiro.card_title1_description')}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className={`mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex ${styles.border02Division2}`}
              >
                <img
                  src={Image06Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subText02}>{t('parceiro.card_title2')}</div>
                <div className={styles.TextDefaultDivision02}>
                  {t('parceiro.card_title2_description')}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className={`mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex ${styles.border03Division2}`}
              >
                <img
                  src={Image02Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subText02}>{t('parceiro.card_title3')}</div>
                <div className="flex-row d-flex text-center">
                  <div className={styles.TextDefaultDivision02}>
                    {t('parceiro.card_title3_description')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.division03}>
        <div className="container px-5">
        <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03}`}
          >
            <span>{t('parceiro.title3')}</span>{t('parceiro.title3_1')}
            <br />{t('parceiro.title3_2')}
          </div>
          <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03_01}`}
          >
            <span>{t('parceiro.title3')}</span>{t('parceiro.title3_1')}
            {t('parceiro.title3_2')}
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="masthead-device-mockup">
              <img
                src={Image08}
                style={{ width: "100%", height: "100%" }}
                alt="Imagem"
              />
            </div>
          </div>
          <div className={styles.subTitle03}>
            {t('parceiro.subtitle3')}
            <br />
            {t('parceiro.subtitle3_1')}
            <br />
            {t('parceiro.subtitle3_2')}
                      
          </div>

          <div
            className={`d-flex flex-lg-row align-items-center justify-content-center ${styles.deviceDivision03_01}`}
          >
            <div className="me-lg-3 mb-lg-0 m-2" onClick={handleRegist}>
              <Button
                name={t('parceiro.button_como_funciona')}
                typeButton="secondary"
                color="black"
              />
            </div>
            <div className="m-2" onClick={handleRegist}>
              <Button name={t('parceiro.button_cadastre_agora')} typeButton="primary" />
            </div>
          </div>
        </div>
        <div className={`col-lg-12 ${styles.imageCourse2}`}>
          <div className="masthead-device-mockup">
            <img
              src={ImageCourse2}
              style={{ width: "100%", height: "100%" }}
              alt="Imagem"
            />
          </div>
        </div>
      </section>
      {/* ************************************************************************* */}

      <section className={styles.division04}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <div className={styles.title04}>
                  {t('parceiro.title4')}
                </div>
                <div className={styles.subTitle04}>
                  {t('parceiro.subtitle4')}
                </div>
                <div className={`col-lg-6 ${styles.deviceDivision04_01}`}>
                  <div className="masthead-device-mockup">
                    <img
                      src={Image01}
                      style={{ width: "100%", height: "100%" }}
                      alt="Imagem"
                    />
                  </div>
                </div>
                <div
                  className={`d-flex flex-column flex-lg-row align-items-center ${styles.btnDivision04}`}
                >
                  <div className="me-lg-3 mb-4 mb-lg-0" onClick={handleRegist}>
                    <Button
                      name={t('parceiro.button_cadastre_escola')}
                      typeButton="secondary"
                      color="black"
                    />
                  </div>
                  <div onClick={handleRegist}>
                    <Button name={t('parceiro.button_cadastre_empresa')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-6 ${styles.deviceDivision04}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image01}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.division05}>
        <div className={`container px-5`}>
          <div className={`row gx-5 align-items-start`}>
            <div className={`col-lg-6 ${styles.deviceDivision05}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image02}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-lg-0 text-center text-lg-start">
                <div className={styles.title05}>{t('afiliado.title5')}</div>
                <div className={styles.subTitle05}>
                  <span className={styles.strongDivision05}>
                    {t('parceiro.title5_1')}
                  </span>
                  <br />
                  <br />
                    {t('parceiro.subtitle5')}
                    <span className={styles.strongDivision05}>{t('parceiro.subtitle5_1')}</span>{" "} {t('parceiro.subtitle5_2')}
                </div>
              </div>
            </div>
            <div className={`col-lg-6 ${styles.deviceDivision05_01}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image02}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.division06}>
        <div className={`masthead ${styles.division06ColorTop}`}>
          <div className="container px-5">
            <div className={styles.title06}>
              {t('parceiro.title6')}
            </div>
            <div className={styles.subTitle06}>{t('parceiro.subtitle6')}</div>
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
              <div className="me-lg-3 mb-4 mb-lg-0" onClick={handleRegist}>
                <Button name={t('parceiro.button_cadastre_agora')} typeButton="primary" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
