import axios from "axios";
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Image31 from "../../../Assets/images/icon/image31.png";
import Image32 from "../../../Assets/images/icon/image32.png";
import Image37 from "../../../Assets/images/icon/image37.png";
import Image13 from "../../../Assets/images/imageAuthor.png";
import { Button, ButtonIcon } from "../../../Components/Button";
import { Footer } from "../../../Components/Public/Footer";
import { Header } from "../../../Components/Public/Header";
import styles from "../Blog/blog.module.scss";
moment.locale('pt-br');

export function BlogNews() {
  const params = useParams()
  const navigate = useNavigate();

  // useEffect(() => {
  //   window.location.href = '/'
  //   window.scroll(0, 1);
  // }, []);

  function handleBack() {
    navigate(-1);
  }

  const { id } = params

  function handleNewsBlog(id: any) {
    // setLoading(true)
    navigate(`/blog/noticias/${id}`);
    window.scroll(0, 1);
  }

  useEffect(() => {
    if (id) loadData(id)
  }, [id])

  const [commentObj, setCommentObj] = useState<any | null>(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')

  const [showLikeButton, setShowLikeButton] = useState(false)
  const [like, setLike] = useState(false)

  useEffect(() => {
    let data = window.sessionStorage.getItem('comment')
    if (data && typeof data === 'string') {
      setCommentObj(JSON.parse(data))
      if (JSON.parse(data).article_id === Number(id)) {
        setShowLikeButton(true)
      }
    }
  }, [window.sessionStorage.getItem('comment')])

  const [article, setArticle] = useState<any>(null)
  const [articles, setArticles] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const loadData = async (id: any) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/articles-site/${id}`)

    if (res?.data && res?.status === 200) {
      setArticle(res.data)
      setLoading(false)
      getFourArticles()
    }
  }

  const getFourArticles = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/articles-site/?limit=${4}&offset=${0}`)

    if (res?.data && res?.status === 200) {
      setArticles(res.data.rows)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (article?.article && typeof (article?.article) === 'string') {
      let el = document.getElementById('article_html')
      if (el) {
        el.innerHTML = article?.article
      }
    }
  }, [article])

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const createComment = async () => {
    if (validateEmail(email)) {
      const data = {
        name, email, comment, article_id: Number(id)
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/comments/`, data)

      if (res?.data) {
        setName('')
        setEmail('')
        setComment('')
        setShowLikeButton(true)
        window.sessionStorage.setItem('comment', JSON.stringify(res?.data))
        loadData(id)

        let textarea: any = document.getElementById('comment_textarea');
        textarea.value = '';
      }
    } else {
      alert('E-mail com formato inválido!')
    }
  }

  const likeArticle = async () => {
    let comment: any = window.sessionStorage.getItem('comment')
    if (comment) comment = JSON.parse(comment)
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/v1/comments/${comment.id}`, { like: true })

    if (res?.data) {
      loadData(id)
      alert('Obrigado por curtir o artigo!')
    }
  }

  return (loading ? <></> :
    <div id="page-top" className={styles.generalContainer}>
      <Header position={5} />

      {/* ************************************************************************* */}

      <header className={`masthead ${styles.divisionBlogNews02}`}>
        <div className={`container ${styles.containerContent}`}>
          <div className="gx-1 align-items-start">
            <div className={styles.containerButton}>
              <div onClick={handleBack}>
                <ButtonIcon name="Voltar" color="black" icon={Image37} />
              </div>
              <span className={styles.title02}>Blog da Next Intake</span>
            </div>
            <div className={styles.newsTitle}>
              {article?.title}
            </div>

            <div className={styles.newsSubTitle}>
              {article?.subtitle}
            </div>

            <div className={` h-100 ${styles.containerImage}`}>
              <img
                src={article?.image}
                className={`card-img-top ${styles.imageNewsBlog}`}
                alt="Imagem"
                style={{ objectFit: 'cover' }}
              />
              <img
                src={Image31}
                alt="Imagem"
                className={styles.imageMessageNewsBlog}
              />
              <div className={styles.circleMessageNewsBlog}>{article?.comments?.length || 0}</div>
              <img
                src={Image32}
                alt="Imagem"
                className={styles.imageHeartNewsBlog}
              />
              <div className={styles.circleHeartNewsBlog}>{article?.comments?.filter((c: any) => c.like)?.length || 0}</div>
              <div className={`card-body ${styles.cardBody}`}>
                <div className={styles.containerAuthor}>
                  <img src={article?.creator?.avatar || Image13} alt={article?.creator?.name} style={{ borderRadius: '150px', objectFit: 'cover' }} />
                  <div>
                    <span>{article?.creator?.name}</span>
                    <span>Equipe Next Intake</span>
                    <div>
                      <span />
                      <span>{moment(article?.updated_at).format('LL')}</span>
                    </div>
                  </div>
                </div>

                <div className={styles.containerBtn}>
                  <div className={styles.btnComment}>
                    <Button
                      name="Comentar"
                      color="black"
                      typeButton="secondary"
                    />
                  </div>
                  {showLikeButton &&
                    <Button onClick={likeArticle} name="Curtir" typeButton="primary" />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={styles.containerText}>

            <div id="article_html" dangerouslySetInnerHTML={{ __html: article?.content}}>
              </div>

            

          </div>

          <div className={styles.lineDivider} />
          <span className={styles.textComments}>Comentários ({article?.comments?.length})</span>

          {article?.comments?.length > 0 ? article.comments.map((item: any, index: any) =>
            <div key={index} className={`card-body ${styles.cardBody}`}>
              <div className={styles.containerComments}>

                <div>{item?.name.slice(0, 1)}</div>

                <div>
                  <span>{item?.name}</span>
                  <div>
                    <span />
                    <span>{moment(article?.created_at).format('LL')}</span>
                  </div>
                  <div>
                    {item?.comment}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className={`card-body ${styles.cardBody}`}>
            <div className={styles.containerComments}>
              <div className={styles.newColor}>T</div>
              <div>
                <span>Tati Pinheiro</span>
                <div>
                  <span />
                  <span>16 de março de 2023</span>
                </div>
                <div>
                  Obrigada. Fico muito feliz pelo feedback! Anote na agenda o
                  próximo evento ein :)
                </div>
              </div>
            </div>
          </div> */}

          <div className={styles.lineDivider} />

          <span className={styles.textComments}>Deixe o seu comentário</span>

          <span className={styles.textEmailAddress}>
            O seu endereço de e-mail não será publicado.
          </span>

          <div className={styles.containerForm}>
            <form className="row g-3">
              <div className={styles.containerInput}>
                <div className={`col-md-6 ${styles.inputText}`}>
                  <label
                    htmlFor="inputEmail4"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Nome completo
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nome Sobrenome"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className={`col-md-6 ${styles.inputTextEmail}`}>
                  <label
                    htmlFor="inputPassword4"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Email
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="meu@email.com"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
              <div className={`col-12 ${styles.textarea}`}>
                <textarea
                  id="comment_textarea"
                  className="form-control"
                  placeholder="Escreva seu comentário"
                  onChange={(e) => setComment(e.target.value)}

                >{comment}</textarea>
                <div className={styles.containerBtnComment}>
                  <Button
                    onClick={createComment}
                    name="Enviar comentário"
                    color="gray"
                    typeButton="secondary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* </section> */}
      </header>

      {/* ************************************************************************* */}

      <section className={`masthead ${styles.division01}`}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-center">
            <span className={styles.titleNewsBlog}>Blog da Next Intake</span>
            <div className="d-flex">
              <div className="col-lg-6">
                <span className={styles.textImage}>Destaque</span>

                {articles && articles.length > 0 ? articles.slice(0, 1).map((item: any, index: any) =>
                  <div key={index} className={`${styles.emphasis}`} onClick={() => handleNewsBlog(item.id)}>
                    <img src={item?.image} alt="Imagem" style={{ width: '80%', height: '250px', objectFit: 'cover' }} />
                    <div className={styles.rowColumn}>
                      <div>
                        {item?.title}
                      </div>
                      <span>{item?.creator?.name ? `Por ${item?.creator?.name}` : ''}</span>
                    </div>
                  </div>
                ) : null}
                {/* <div className={`${styles.emphasis}`} onClick={handleNewsBlog}>
                  <img src={Image15} alt="Imagem" />
                  <div className={styles.rowColumn}>
                    <div>
                      Consulado Canadense participa de webinar com a Canadá Sem
                      Fronteiras
                    </div>
                    <span>Por Tati Pinheiro</span>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6">
                <span className={styles.textImage}>Mais populares</span>
                <div className={styles.morePopular}>

                  {articles && articles.length > 0 ? articles.slice(1, 4).map((item: any, index: any) =>
                    <div key={index} className={styles.rowContent} onClick={() => handleNewsBlog(item.id)}>
                      <img src={item?.image} alt="Imagem" style={{ objectFit: 'cover' }} />
                      <div className={styles.rowColumn}>
                        <div>
                          {item?.title}
                        </div>
                        <span>{item?.creator?.name ? `Por ${item?.creator?.name}` : ''}</span>
                      </div>
                    </div>
                  ) : null}
                  {/* <div className={styles.rowContent} onClick={handleNewsBlog}>
                    <img src={Image15} alt="Imagem" />
                    <div className={styles.rowColumn}>
                      <div>
                        Consulado Canadense participa de webinar com a Canadá
                        Sem Fronteiras
                      </div>
                      <span>Por Tati Pinheiro</span>
                    </div>
                  </div>
                  <div className={styles.rowContent} onClick={handleNewsBlog}>
                    <img src={Image15} alt="Imagem" />
                    <div className={styles.rowColumn}>
                      <div>
                        Consulado Canadense participa de webinar com a Canadá
                        Sem Fronteiras
                      </div>
                      <span>Por Tati Pinheiro</span>
                    </div>
                  </div>
                  <div className={styles.rowContent} onClick={handleNewsBlog}>
                    <img src={Image15} alt="Imagem" />
                    <div className={styles.rowColumn}>
                      <div>
                        Consulado Canadense participa de webinar com a Canadá
                        Sem Fronteiras
                      </div>
                      <span>Por Tati Pinheiro</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ************************************************************************* */}

      <section className={styles.division03}>
        <div className={`masthead ${styles.division03ColorTop}`}>
          <div className="container px-5">
            <div className={`row gx-1 ${styles.containerFoot}`}>
              <div className="col-lg-6">
                <span className={styles.titleFoot}>
                  Gostou do que está vendo por aqui?
                </span>
                <span className={styles.subTitleFoot}>
                  Assine nossa newsletter e receba novos conteúdos por e-mail
                </span>
              </div>
              <div className="col-lg-6">
                <form className={`row g-3`}>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      Nome completo<span className={styles.asterisk}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      placeholder="Nome Sobrenome"
                      value=""
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      Email<span className={styles.asterisk}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      placeholder="meu@email.com"
                      value=""
                      required
                    />
                    <div className={`col-md-12 form-check ${styles.checkForm}`}>
                      <input
                        className={`form-check-input ${styles.formCheck}`}
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked
                      />
                      <label
                        className={`form-check-label ${styles.textCheck}`}
                        htmlFor="flexCheckChecked"
                      >
                        Desejo receber os novos conteúdos do Blog da Next Intake
                      </label>
                    </div>
                  </div>
                  <br />
                  <div className={styles.section05}>
                    <Button
                      name="Quero receber"
                      // onClick={handleLogin}
                      typeButton="primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
