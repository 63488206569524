import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "../ApplicationPortal/ApplicationPortal.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function ApplicationPortal() {
  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate("/informacoes-pessoais");
  };
  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={false} positionIcon={1}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Portal de Aplicação</span>
            </div>

            <div className={styles.description}>
              <span>
                Antes de enviar sua inscrição, convidamos você a saber mais
                sobre nossos programas e os requisitos de envio deles.
              </span>
            </div>

            <div className={styles.description}>
              <span>
                Para um processamento mais rápido, certifique-se de ter todos os
                documentos de requisitos digitalizados (em arquivos separados) e
                prontos para upload e sua taxa de inscrição pronta para pagar.
              </span>
            </div>

            <div className={styles.description}>
              <span>
                Assim que sua inscrição for concluída e enviada com sucesso,
                você receberá um e-mail nosso.
              </span>
            </div>
            <div className={styles.margeBtn} onClick={() => handleNextScreen()}>
              <Button name="Completar cadastro" typeButton="primary" />
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
