import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { PatternFormat } from "react-number-format";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import styles from "../PersonalInformation/PersonalInformation.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

interface ICountriesProps {
  id: number;
  name: string;
}

export function PersonalInformation() {
  const navigate = useNavigate();
  const [value, setValue] = useState<any>();
  const [dateBirth, setDateBirth] = useState<any>(new Date());
  const [emailData, setEmailData] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [middleLastName, setMiddleLastName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [numberData, setNumberData] = useState<string>("");
  const [complement, setComplement] = useState<string>("");

  let inputRef: any;

  const handleNextScreen = () => {
    navigate("/contato-emergencia");
  };

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  const countries: ICountriesProps[] = [
    { id: 1, name: "Afeganistão" },
    { id: 2, name: "África do Sul" },
    { id: 3, name: "Albânia" },
    { id: 4, name: "Alemanha" },
    { id: 5, name: "Andorra" },
    { id: 6, name: "Angola" },
    { id: 7, name: "Antiga e Barbuda" },
    { id: 8, name: "Arábia Saudita" },
    { id: 9, name: "Argélia" },
    { id: 10, name: "Argentina" },
    { id: 11, name: "Arménia" },
    { id: 12, name: "Austrália" },
    { id: 13, name: "Áustria" },
    { id: 14, name: "Azerbaijão" },
    { id: 15, name: "Bahamas" },
    { id: 16, name: "Bangladexe" },
    { id: 17, name: "Barbados" },
    { id: 18, name: "Barém" },
    { id: 19, name: "Bélgica" },
    { id: 20, name: "Belize" },
    { id: 21, name: "Benim" },
    { id: 22, name: "Bielorrússia" },
    { id: 23, name: "Bolívia" },
    { id: 24, name: "Bósnia e Herzegovina" },
    { id: 25, name: "Botsuana" },
    { id: 26, name: "Brasil" },
    { id: 27, name: "Brunei" },
    { id: 28, name: "Bulgária" },
    { id: 29, name: "Burquina Faso" },
    { id: 30, name: "Burúndi" },
    { id: 31, name: "Butão" },
    { id: 32, name: "Cabo Verde" },
    { id: 33, name: "Camarões" },
    { id: 34, name: "Camboja" },
    { id: 35, name: "Canadá" },
    { id: 36, name: "Catar" },
    { id: 37, name: "Cazaquistão" },
    { id: 38, name: "Chade" },
    { id: 39, name: "Chile" },
    { id: 40, name: "China" },
    { id: 41, name: "Chipre" },
    { id: 42, name: "Colômbia" },
    { id: 43, name: "Comores" },
    { id: 44, name: "Congo-Brazzaville" },
    { id: 45, name: "Coreia do Norte" },
    { id: 46, name: "Coreia do Sul" },
    { id: 47, name: "Cosovo" },
    { id: 48, name: "Costa do Marfim" },
    { id: 49, name: "Costa Rica" },
    { id: 50, name: "Croácia" },
    { id: 51, name: "Cuaite" },
    { id: 52, name: "Cuba" },
    { id: 53, name: "Dinamarca" },
    { id: 54, name: "Dominica" },
    { id: 55, name: "Egito" },
    { id: 56, name: "Emirados Árabes Unidos" },
    { id: 57, name: "Equador" },
    { id: 58, name: "Eritreia" },
    { id: 59, name: "Eslováquia" },
    { id: 60, name: "Eslovénia" },
    { id: 61, name: "Espanha" },
    { id: 62, name: "Essuatíni" },
    { id: 63, name: "Estado da Palestina" },
    { id: 64, name: "Estados Unidos" },
    { id: 65, name: "Estónia" },
    { id: 66, name: "Etiópia" },
    { id: 67, name: "Fiji" },
    { id: 68, name: "Filipinas" },
    { id: 69, name: "Finlândia" },
    { id: 70, name: "França" },
    { id: 71, name: "Gabão" },
    { id: 72, name: "Gâmbia" },
    { id: 73, name: "Gana" },
    { id: 74, name: "Geórgia" },
    { id: 75, name: "Granada" },
    { id: 76, name: "Grécia" },
    { id: 77, name: "Guatemala" },
    { id: 78, name: "Guiana" },
    { id: 79, name: "Guiné" },
    { id: 80, name: "Guiné Equatorial" },
    { id: 81, name: "Guiné-Bissau" },
    { id: 82, name: "Haiti" },
    { id: 83, name: "Honduras" },
    { id: 84, name: "Hungria" },
    { id: 85, name: "Iémen" },
    { id: 86, name: "Ilhas Marechal" },
    { id: 87, name: "Índia" },
    { id: 88, name: "Indonésia" },
    { id: 89, name: "Irão" },
    { id: 90, name: "Iraque" },
    { id: 91, name: "Irlanda" },
    { id: 92, name: "Islândia" },
    { id: 93, name: "Israel" },
    { id: 94, name: "Itália" },
    { id: 95, name: "Jamaica" },
    { id: 96, name: "Japão" },
    { id: 97, name: "Jibuti" },
    { id: 98, name: "Jordânia" },
    { id: 99, name: "Laus" },
    { id: 100, name: "Lesoto" },
    { id: 101, name: "Letónia" },
    { id: 102, name: "Líbano" },
    { id: 103, name: "Libéria" },
    { id: 104, name: "Líbia" },
    { id: 105, name: "Listenstaine" },
    { id: 106, name: "Lituânia" },
    { id: 107, name: "Luxemburgo" },
    { id: 108, name: "Macedónia do Norte" },
    { id: 109, name: "Madagáscar" },
    { id: 110, name: "Malásia" },
    { id: 111, name: "Maláui" },
    { id: 112, name: "Maldivas" },
    { id: 113, name: "Mali" },
    { id: 114, name: "Malta" },
    { id: 115, name: "Marrocos" },
    { id: 116, name: "Maurícia" },
    { id: 117, name: "Mauritânia" },
    { id: 118, name: "México" },
    { id: 119, name: "Mianmar" },
    { id: 120, name: "Micronésia" },
    { id: 121, name: "Moçambique" },
    { id: 122, name: "Moldávia" },
    { id: 123, name: "Mónaco" },
    { id: 124, name: "Mongólia" },
    { id: 125, name: "Montenegro" },
    { id: 126, name: "Namíbia" },
    { id: 127, name: "Nauru" },
    { id: 128, name: "Nepal" },
    { id: 129, name: "Nicarágua" },
    { id: 130, name: "Níger" },
    { id: 131, name: "Nigéria" },
    { id: 132, name: "Noruega" },
    { id: 133, name: "Nova Zelândia" },
    { id: 134, name: "Omã" },
    { id: 135, name: "Países Baixos" },
    { id: 136, name: "Palau" },
    { id: 137, name: "Panamá" },
    { id: 138, name: "Papua Nova Guiné" },
    { id: 139, name: "Paquistão" },
    { id: 140, name: "Paraguai" },
    { id: 141, name: "Peru" },
    { id: 142, name: "Polónia" },
    { id: 143, name: "Portugal" },
    { id: 144, name: "Quénia" },
    { id: 145, name: "Quirguistão" },
    { id: 146, name: "Quiribáti" },
    { id: 147, name: "Reino Unido" },
    { id: 148, name: "República Centro-Africana" },
    { id: 149, name: "República Checa" },
    { id: 150, name: "República Democrática do Congo" },
    { id: 151, name: "República Dominicana" },
    { id: 152, name: "Roménia" },
    { id: 153, name: "Ruanda" },
    { id: 154, name: "Rússia" },
    { id: 155, name: "Salomão" },
    { id: 156, name: "Salvador" },
    { id: 157, name: "Samoa" },
    { id: 158, name: "Santa Lúcia" },
    { id: 159, name: "São Cristóvão e Neves" },
    { id: 160, name: "São Marinho" },
    { id: 161, name: "São Tomé e Príncipe" },
    { id: 162, name: "São Vicente e Granadinas" },
    { id: 163, name: "Seicheles" },
    { id: 164, name: "Senegal" },
    { id: 165, name: "Serra Leoa" },
    { id: 166, name: "Sérvia" },
    { id: 167, name: "Síria" },
    { id: 168, name: "Somália" },
    { id: 169, name: "Sri Lanca" },
    { id: 170, name: "Sudão" },
    { id: 171, name: "Sudão do Sul" },
    { id: 172, name: "Suécia" },
    { id: 173, name: "Suíça" },
    { id: 174, name: "Suriname" },
    { id: 175, name: "Tailândia" },
    { id: 176, name: "Taiuã" },
    { id: 177, name: "Tajiquistão" },
    { id: 178, name: "Tanzânia" },
    { id: 179, name: "Timor-Leste" },
    { id: 180, name: "Togo" },
    { id: 181, name: "Tonga" },
    { id: 182, name: "Trindade e Tobago" },
    { id: 183, name: "Tunísia" },
    { id: 184, name: "Turcomenistão" },
    { id: 185, name: "Turquia" },
    { id: 186, name: "Tuvalu" },
    { id: 187, name: "Ucrânia" },
    { id: 188, name: "Uganda" },
    { id: 189, name: "Uruguai" },
    { id: 190, name: "Usbequistão" },
    { id: 191, name: "Vanuatu" },
    { id: 192, name: "Vaticano" },
    { id: 193, name: "Venezuela" },
    { id: 194, name: "Vietname" },
    { id: 195, name: "Zâmbia" },
    { id: 196, name: "Zimbábue" },
  ];

  const checkTextNumber = (text: string) => {
    const valueNumber = text.replace(/[^0-9]/g, "");
    if (valueNumber.length > 0) {
      setNumberData(valueNumber);
    } else {
      setNumberData(valueNumber);
    }
  };

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={2}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Informações pessoais</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    E-mail<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="email@email.com"
                    value={emailData}
                    onChange={(e) => setEmailData(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Primeiro nome<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="Nome"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Sobrenome do meio<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="Sobrenome"
                    value={middleLastName}
                    onChange={(e) => setMiddleLastName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Último sobrenome<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="Sobrenome"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Celular<span className={styles.asterisk}>*</span>
                  </label>
                  <div className={`input-group ${styles.phoneInputGroup}`}>
                    <PhoneInput
                      placeholder="Insira o telefone aqui"
                      defaultCountry={"BR"}
                      limitMaxLength={true}
                      value={value}
                      onChange={(e) => setValue(e)}
                      className={`form-control ${styles.textInput}`}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Data de nascimento<span className={styles.asterisk}>*</span>
                  </label>
                  <div className={`input-group ${styles.inputGroupCalendar}`}>
                    <DatePicker
                      onChange={setDateBirth}
                      value={dateBirth}
                      className={`form-control ${styles.textInputCalendar}`}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Gênero<span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Qual o seu gênero?</option>
                    <option value="1">Masculino</option>
                    <option value="2">Feminino</option>
                    <option value="3">Outro</option>
                    <option value="4">Prefiro não dizer</option>
                  </select>
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Nacionalidade<span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    {countries.length > 0 &&
                      countries.map((value) => (
                        <option key={value.id}>{value.name}</option>
                      ))}
                  </select>
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Anexar o passaporte
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="file"
                    name="passport"
                    className={styles.formFile}
                    ref={(refParam) => (inputRef = refParam)}
                    hidden={true}
                  />
                  <div className={styles.containerPassport01}>
                    <div
                      className={styles.containerPassport02}
                      onClick={() => inputRef.click()}
                    >
                      <div className={styles.textPassport01}>
                        Solte o arquivo para anexar ou
                        <span className={styles.textPassport02}> procure</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Status de residência no Canadá
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">Espanhol</option>
                    <option value="2">Inglês</option>
                    <option value="3">Português</option>
                  </select>
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Língua materna
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">Espanhol</option>
                    <option value="2">Inglês</option>
                    <option value="3">Português</option>
                  </select>
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Você tem uma deficiência/condição física e/ou de
                    aprendizagem que deseja divulgar?
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className={`col-md-12`}>
                  <div className={styles.formInputSelect}>
                    <div>
                      <label
                        form="validationDefault02"
                        className={`form-label ${styles.textLabel}`}
                      >
                        País<span className={styles.asterisk}>*</span>
                      </label>
                      <select
                        className={`form-select ${styles.formDropdown} ${styles.smallerData}`}
                        aria-label="Default select example"
                        placeholder="Estudar no Canadá(Estudante)"
                      >
                        <option selected>Selecione</option>
                        {countries.length > 0 &&
                          countries.map((value) => (
                            <option key={value.id}>{value.name}</option>
                          ))}
                      </select>
                    </div>

                    <div>
                      <label
                        form="validationDefault02"
                        className={`form-label ${styles.textLabel}`}
                      >
                        CEP<span className={styles.asterisk}>*</span>
                      </label>
                      <PatternFormat
                        format="#####-###"
                        allowEmptyFormatting
                        mask="_"
                        className={`form-control ${styles.smallerData}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Estado/província
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="Estado / província"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Endereço<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="Endereço"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <div className={styles.formInputSelect}>
                    <div>
                      <label
                        form="validationDefault02"
                        className={`form-label ${styles.textLabel}`}
                      >
                        Número<span className={styles.asterisk}>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${styles.smallerData}`}
                        placeholder="999"
                        value={numberData}
                        onChange={(e) => checkTextNumber(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <label
                        form="validationDefault02"
                        className={`form-label ${styles.textLabel}`}
                      >
                        Complemento<span className={styles.asterisk}>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${styles.smallerData}`}
                        placeholder="Ap 101"
                        value={complement}
                        onChange={(e) => setComplement(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Seu endereço permanente é diferente do preenchido acima?
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${styles.radioAddress}`}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className={`form-check-label ${styles.textLabel}`}
                      htmlFor="flexRadioDefault1"
                    >
                      Sim
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${styles.radioAddress}`}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      // checked
                    />
                    <label
                      className={`form-check-label ${styles.textLabel}`}
                      htmlFor="flexRadioDefault2"
                    >
                      Não
                    </label>
                  </div>
                </div>
                <br />
                <div className="col-md-12" onClick={() => handleNextScreen()}>
                  <Button name="Próxima etapa" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
