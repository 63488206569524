import { useEffect } from "react";
import { MenuHeader } from "../../../Components/Public/Header/MenuHeader";
import "react-alice-carousel/lib/alice-carousel.css";
import styles from "../Register/Register.module.scss";
import { Button } from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
// import Image11 from "../../../Assets/images/icon/image11.png";
// import Image12 from "../../../Assets/images/icon/image12.png";

export function Register() {
  const navigate = useNavigate();
  
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/subscribe`
    // navigate("/");
  }, []);

  const goTo = () => {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/subscribe`
  }

  return (
    <div id="page-top">
      {/* <MenuHeader /> */}
      <section className={`masthead ${styles.section01}`}>

        
        {/* <div className={`container ${styles.container}`}>
          <div className={styles.title01}>
            <span>Boas-vindas à Next Intake</span>
          </div>
          <div className={styles.subTitle01}>
            Deseja saber mais sobre a nossa plataforma? Cadastre-se e fique por
            dentro de todas as novidades.
          </div>

          <div className={styles.section04}>
            <form className="row g-3">
              <div className="col-md-12">
                <label
                  form="validationDefault01"
                  className={`form-label ${styles.textLabel}`}
                >
                  Nome completo<span className={styles.asterisk}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault01"
                  placeholder="Seu Nome Completo"
                  value=""
                  required
                />
              </div>
              <div className="col-md-12">
                <label
                  form="validationDefault02"
                  className={`form-label ${styles.textLabel}`}
                >
                  E-mail<span className={styles.asterisk}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault02"
                  placeholder="seuemail@email.com.br"
                  value=""
                  required
                />
              </div>
              <div className="col-md-12">
                <label
                  form="validationDefault02"
                  className={`form-label ${styles.textLabel}`}
                >
                  Qual o seu objetivo<span className={styles.asterisk}>*</span>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  placeholder="Estudar no Canadá(Estudante)"
                >
                  <option selected>Estudar no Canadá(Estudante)</option>
                  <option value="1">Ser um parceiro</option>
                  <option value="2">Ser um afiliado</option>
                </select>
              </div>
              <br />
              <div className="col-md-12">
                <Button name="Cadastrar" typeButton="primary" />
              </div>
            </form>
          </div>
        </div> */}
      </section>
    </div>
  );
}
