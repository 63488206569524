import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";
import PhoneInput from "react-phone-number-input";

import styles from "../EmergencyContact/EmergencyContact.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function EmergencyContact() {
  const navigate = useNavigate();

  const [value, setValue] = useState<any>();
  const [emailData, setEmailData] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const handleNextScreen = () => {
    navigate("/historico-escolar");
  };

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={3}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Contato de emergência</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    E-mail<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="email@email.com"
                    value={emailData}
                    onChange={(e) => setEmailData(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Primeiro nome<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="Nome"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Último sobrenome<span className={styles.asterisk}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.inputControl}`}
                    placeholder="Sobrenome"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Celular<span className={styles.asterisk}>*</span>
                  </label>
                  <div className={`input-group ${styles.phoneInputGroup}`}>
                    <PhoneInput
                      placeholder="Insira o telefone aqui"
                      defaultCountry={"BR"}
                      limitMaxLength={true}
                      value={value}
                      onChange={(e) => setValue(e)}
                      className={`form-control ${styles.textInput}`}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Relação com o candidato
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <br />
                <div className="col-md-12" onClick={() => handleNextScreen()}>
                  <Button name="Próxima etapa" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
