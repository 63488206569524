import React, { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";
interface IButtonProps {
  name: string;
  typeElement?: ButtonHTMLAttributes<HTMLButtonElement>;
  color?: "white" | "black" | "gray";
  typeButton: "primary" | "secondary";
  onClick?: any;
  valueClick?: any;
  height?: number;
  disabled?: boolean
}

const Button: React.FC<IButtonProps> = ({
  name,
  typeElement,
  color,
  typeButton,
  onClick,
  valueClick,
  height = 48,
  disabled
}) => {
  const eventHandler = () => {
    if (onClick) {
      valueClick ? onClick(valueClick) : onClick();
    }
  };
  if (typeButton === "primary") {
    return (
      <button
        type={typeElement?.type ? typeElement.type : "button"}
        className={`btn ${styles.primary} ${styles.infoButton}`}
        onClick={() => eventHandler()}
        style={{ height }}
        disabled={disabled}
      >
        {name}
      </button>
    );
  } else {
    const checkColor =
      color === "black" ? styles.secondaryBlack : styles.secondaryWhite;
    const typeColor = color === "gray" ? styles.secondaryGray : checkColor;
    return (
      <button
        type={typeElement?.type ? typeElement.type : "button"}
        className={`btn ${styles.infoButton} ${typeColor}`}
        onClick={() => eventHandler()}
      >
        {name}
      </button>
    );
  }
};

interface IButtonIconProps {
  name: string;
  color?: "white" | "black" | "gray";
  onClick?: any;
  valueClick?: any;
  icon?: any;
}

const ButtonIcon: React.FC<IButtonIconProps> = ({
  name,
  color,
  onClick,
  valueClick,
  icon,
}) => {
  const eventHandler = () => {
    if (onClick) {
      valueClick ? onClick(valueClick) : onClick();
    }
  };

  const checkColor =
    color === "black" ? styles.secondaryBlack : styles.secondaryWhite;
  const typeColor = color === "gray" ? styles.secondaryGray : checkColor;
  return (
    <div
      onClick={() => eventHandler()}
      className={`btn ${styles.infoButtonImg} ${typeColor}`}
    >
      <img
        src={icon}
        className={`card-img-top ${styles.iconImage}`}
        alt="Imagem"
      />
      {name}
    </div>
  );
};

export { Button, ButtonIcon };
