import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "../SchoolRecords/SchoolRecords.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function SchoolRecords() {
  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate("/proficiencia-ingles");
  };

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={4}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Histórico escolar</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <span className={`form-label ${styles.institutionText}`}>
                  Instituição 1
                </span>
                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    País
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Nível de estudo<span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Concluído<span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Nome da instituição
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Ano de Início<span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Ano de conclusão<span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Anexar o histórico escolar
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <div className={styles.containerPassport01}>
                    <div className={styles.containerPassport02}>
                      <div className={styles.textPassport01}>
                        Solte o arquivo para anexar ou
                        <span className={styles.textPassport02}> procure</span>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <div
                  className={`col-md-12 ${styles.addAnotherInstitution}`}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Button
                    name="Adicionar outra instituição"
                    typeButton="secondary"
                    color="black"
                  />
                </div>

                <div className="col-md-12">
                  <label
                    form="validationDefault02"
                    className={`form-label ${styles.textLabel}`}
                  >
                    Você já foi obrigado a desistir ou recebeu uma
                    suspensão/probatória acadêmica?
                    <span className={styles.asterisk}>*</span>
                  </label>
                  <select
                    className={`form-select ${styles.formDropdown}`}
                    aria-label="Default select example"
                    placeholder="Estudar no Canadá(Estudante)"
                  >
                    <option selected>Selecione</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <br />
                <div className="col-md-12" onClick={() => handleNextScreen()}>
                  <Button name="Próxima etapa" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
