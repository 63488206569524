import { useEffect, useState } from "react";
import i18n from "i18next";
import BrazilIcon from '../../../Assets/images/icon/Language-Brasil.png'
import CanadaIcon from '../../../Assets/images/icon/Language-Canada.png'
import EspanhaIcon from '../../../Assets/images/icon/Language-Espanha.png'

export function LanguageChange(props: any) {

    async function checkSelectedLanguage() {        
        let lang = getSelectedLanguage()        
        setLanguage(lang)
        i18n.changeLanguage(lang)
    }

    // const [Language, setLanguage] = useState('pt-BR');
    // const [Language, setLanguage] = useState('en-US');
    const [Language, setLanguage] = useState('es');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {        
        checkSelectedLanguage()
    }, [])

    const setNewLangague = (lang: string) => {
        localStorage.setItem("language", lang)
        setLanguage(lang)
        setIsOpen(false)
        i18n.changeLanguage(lang)
        props.changeLanguage && props.changeLanguage()
    }

    return <>
        <div className="m-4" style={{position: 'relative'}}>

            <div 
                style={{paddingLeft: 8}}
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                {
                    Language === 'pt-BR' ?
                        <img src={BrazilIcon} style={{marginLeft: 3}} width={32} height={32}/>    
                    : Language === 'en-US' ?
                        <img src={CanadaIcon} style={{marginLeft: 3}} width={32} height={32}/>    
                    : Language === 'es' &&      
                        <img src={EspanhaIcon} style={{marginLeft: 3}} width={32} height={32}/>    
                }
            </div>
            
            <div style={{
                position: 'absolute', 
                display: "flex", 
                gap: 8, 
                marginTop: 8, 
                flexDirection: 'column', 
                borderRadius: 8, 
                padding: 8, 
                background: '#0058D0', 
                transition: "all .2s",
                opacity: !isOpen ? "0" : "1",
                visibility: !isOpen ? "hidden" : "visible",
            }}>
                <div onClick={() => {setNewLangague('pt-BR')}} style={{borderRadius: 32, border: Language === 'pt-BR' ? '3px solid #FFF': 'none', marginLeft: Language === 'pt-BR' ? 0 : 3}}>
                    <img src={BrazilIcon} width={32} height={32}/>
                </div>
                <div onClick={() => {setNewLangague('en-US')}} style={{borderRadius: 32, border: Language === 'en-US' ? '3px solid #FFF': 'none', marginLeft: Language === 'en-US' ? 0 : 3}}>
                    <img src={CanadaIcon} width={32} height={32}/>
                </div>
                <div onClick={() => {setNewLangague('es')}} style={{borderRadius: 32, border: Language === 'es' ? '3px solid #FFF': 'none', marginLeft: Language === 'es' ? 0 : 3}}>
                    <img src={EspanhaIcon} width={32} height={32}/>
                </div>
            </div>
            
            
        </div>
    </>
}

export function getSelectedLanguage() {
    let lang = 'pt-BR'
    //let lang = 'en-US' 
    // let lang = 'es' 
    try {
        let temp = localStorage.getItem("language");
        if (temp) {
            lang = temp
        }        
    }
    catch(e) {
        console.log(e)
    }

    return lang
}