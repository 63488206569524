import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Private/Header";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "../ReviewComplete/ReviewComplete.module.scss";
import { TimeLineComp } from "../../../Components/Private/TimeLine";
import { Button } from "../../../Components/Button";

export function ReviewComplete() {
  const navigate = useNavigate();

  const handleNextScreen = () => {
    navigate("/termos-condicoes");
  };

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header />
      <section className={`masthead ${styles.section01}`}>
        <TimeLineComp iconChecked={true} positionIcon={8}>
          <div className="container px-5 col-6">
            <div className={styles.title01}>
              <span>Revisar e concluir</span>
            </div>

            <div className={styles.section02}>
              <form className="row g-3">
                <ul className={`form-label ${styles.infoTitle}`}>
                  <li>
                    Aqui deve aparecer as perguntas e respostas preenchidas
                    pelos usuários. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.
                  </li>{" "}
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </li>
                </ul>

                <br />
                <div className="col-md-12" onClick={() => handleNextScreen()}>
                  <Button name="Próxima etapa" typeButton="primary" />
                </div>
              </form>
            </div>
          </div>
        </TimeLineComp>
      </section>
    </div>
  );
}
