import { useNavigate } from "react-router-dom";

import logo from "../../../Assets/images/logo.png";
import { Button } from "../../Button";
import styles from "./Header.module.scss";
import { LanguageChange } from "./language";
import { useTranslation } from "react-i18next";

interface IHeaderProps {
  position?: number;
  changeLanguage?: () => void
}

export function Header({ position, changeLanguage }: IHeaderProps) {
  const { t } = useTranslation()
  const navigate = useNavigate();

  function handleHome() {
    navigate("/");
  }

  function handleWhatWeDo() {
    navigate("/o-que-fazemos");
  }

  function handleHowItWorks() {
    navigate("/como-funciona");
  }

  function handleBeAnAffiliate() {
    navigate("/seja-um-afiliado");
  }

  function handleBeAPartner() {
    navigate("/seja-um-parceiro");
  }

  function handleQuestions() {
    navigate("/perguntas");
  }

  function handleBlog() {
    navigate("/blog");
  }

  function handleClick() {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/login`
    // navigate("/login");
  }

  function handleRegist() {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/subscribe`
    // navigate("/cadastre-se");
  }

  const hidden = false;

  return (
    // <nav
    //   className={`navbar navbar-expand-lg navbar-light fixed-top shadow-sm ${styles.background}`}
    //   id="mainNav"
    // >
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top ${styles.background}`}
      id="mainNav"
    >
      <div className="container">
        {/* <div className="container px-5"> */}
        {/* <a className="navbar-brand fw-bold" href="#page-top">
          <img className="app-badge" src={logo} alt="..." />
        </a> */}
        <div onClick={handleHome}>
          <img
            className={`app-badge ${styles.imageLogo}`}
            src={logo}
            alt="..."
          />
        </div>
        <button
          className={`navbar-toggler ${styles.menuToggler}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i className="bi-list"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul
            className={`navbar-nav ms-auto me-4 my-3 my-lg-0 ${styles.textHeader}`}
          >
            <li className="nav-item">
              <div
                className={`nav-link me-lg-3 ${position === 0
                  ? styles.colorLinkActive
                  : styles.colorLinkDisable
                  }`}
                onClick={handleWhatWeDo}
              >
                {t('menu.menu1')}
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link me-lg-3 ${position === 1
                  ? styles.colorLinkActive
                  : styles.colorLinkDisable
                  }`}
                onClick={handleHowItWorks}
              >
                {t('menu.menu2')}
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link me-lg-3 ${position === 2
                  ? styles.colorLinkActive
                  : styles.colorLinkDisable
                  }`}
                onClick={handleBeAnAffiliate}
              >
                {t('menu.menu3')}
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link me-lg-3 ${position === 3
                  ? styles.colorLinkActive
                  : styles.colorLinkDisable
                  }`}
                onClick={handleBeAPartner}
              >
                {t('menu.menu4')}
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link me-lg-3 ${position === 4
                  ? styles.colorLinkActive
                  : styles.colorLinkDisable
                  }`}
                onClick={handleQuestions}
              >
                {t('menu.menu5')}
              </div>
            </li>

            <li className="nav-item">
              <div
                className={`nav-link me-lg-3 ${position === 5
                    ? styles.colorLinkActive
                    : styles.colorLinkDisable
                  }`}
                onClick={handleBlog}
              >
                {t('menu.menu6')}
              </div>
            </li>
            
            <li className="nav-item">
              <Button
                name={t('menu.button_entrar')}
                onClick={handleClick}
                typeButton="secondary"
              />
            </li>
          

            {/* <li className="nav-item">
              <Button
                name={t('menu.button_cadastre_agora')}
                onClick={handleRegist}
                typeButton="primary"
              />
            </li> */}
            <li className="nav-item">
              <LanguageChange
                changeLanguage={changeLanguage ? changeLanguage : ()=>{}}
              />
            </li>
          </ul>
          {/* <form>
            <button
              // onClick={() => appTeste()}
              onClick={handleClick}
              type={"button"}
            >
              Entrar
            </button>
          </form> */}
          {/* <button
            className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
            data-bs-toggle="modal"
            data-bs-target="#feedbackModal"
          >
            <span className="d-flex align-items-center">
              <i className="bi-chat-text-fill me-2"></i>
              <span className="small">Cadastre-se agora</span>
            </span>
          </button> */}
          {/* <Button name="Entrar" typeButton="primary" /> */}
          
        </div>
      </div>
    </nav>
  );
}
