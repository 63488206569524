import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../Components/Public/Header";
import styles from "../Affiliate/Affiliate.module.scss";
import Image00 from "../../../Assets/images/image11.png";
import ImageCourse2 from "../../../Assets/images/course2.png";
import Image08 from "../../../Assets/images/affiliated.png";
import Image02Division02 from "../../../Assets/images/icon/image02.png";
import Image05Division02 from "../../../Assets/images/icon/image01.png";
import Image06Division02 from "../../../Assets/images/icon/image04.png";
import Image02 from "../../../Assets/images/image12.png";
import { Button } from "../../../Components/Button";
import "react-alice-carousel/lib/alice-carousel.css";
import { Footer } from "../../../Components/Public/Footer";
import { useTranslation } from "react-i18next";

export function Affiliate() {
  const { t } = useTranslation()
  const navigate = useNavigate();

  function handleWhatWeDo() {
    navigate("/o-que-fazemos");
  }

  function handleHowItWorks() {
    navigate("/como-funciona");
  }

  function handleRegist() {
    window.location.href = `${process.env.REACT_APP_NEXT_INTAKE_URL}/subscribe`
    // navigate("/cadastre-se");
  }

  useEffect(() => {
    window.scroll(0, 1);
  }, []);

  return (
    <div id="page-top">
      <Header position={2} />
      <header className={`${styles.division01}`}>
        <div className={`masthead ${styles.container01ColorTop}`}>
          <div className="row d-flex gx-5 align-items-center">
            <div className={`col-lg-7 ${styles.imageTop}`}>
              <div className={`container px-5 ${styles.containerDivision01}`}>
                <div className="mb-5 mb-lg-0 text-center text-lg-start">
                  <div className={`${styles.title01}`}>{t('afiliado.title1')}</div>
                  <div className={`${styles.subTitle01}`}>
                    {t('afiliado.subtitle1')}
                  </div>
                  <div
                    className={`d-flex flex-lg-row align-items-center ${styles.btnDivision01} ${styles.deviceDivision01}`}
                  >
                    <div onClick={handleRegist}>
                      <Button name={t('afiliado.button_cadastre_agora')} typeButton="primary" />
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex flex-lg-row mt-3 justify-content-center align-items-center ${styles.deviceDivision01_02}`}
                >
                  <div className="m-2" onClick={handleRegist}>
                    <Button name={t('afiliado.button_cadastre_agora')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-5`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image00}
                  // style={{ width: 660, height: 455 }}
                  style={{ width: 792, height: 533 }}
                  alt="Imagem"
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className={styles.division02}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-start">
            <div className={styles.title02}>
              {t('afiliado.title2')}
            </div>
            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <div
                  style={{ marginBottom: 15, marginTop: 40 }}
                  onClick={handleRegist}
                >
                  <Button name="Cadastre-se agora" typeButton="primary" />
                </div>
                <div className={styles.subText02}>{t('afiliado.indicator_1_Number')}</div>
                <div className={styles.Text01Division02}>
                {t('afiliado.indicator_1_Description_1')}{" "}
                  <span className={styles.TextDefaultDivision02}>{t('afiliado.indicator_1_Description_2')}</span>
                </div>
                <div className={styles.TextDefaultDivision02}>{t('afiliado.indicator_1_Description_3')}</div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <img
                  src={Image05Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subText02}>{t('afiliado.indicator_2_Number')}</div>
                <div className={styles.Text02Division02}>
                  {t('afiliado.indicator_2_Description_1')}{" "}
                  <span className={styles.TextDefaultDivision02}>{t('afiliado.indicator_2_Description_2')}{t('afiliado.indicator_2_Description_3')}</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <img
                  src={Image06Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subText02}>{t('afiliado.indicator_3_Number')}</div>
                <div className={styles.Text03Division02}>{t('afiliado.indicator_3_Description_1')}</div>
                <div className={styles.TextDefaultDivision02Center}>
                  {t('afiliado.indicator_3_Description_2')}
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="mb-5 mb-lg-0 text-center text-lg-start flex-column align-items-center d-flex">
                <img
                  src={Image02Division02}
                  style={{ width: 96, height: 96 }}
                  alt="Imagem"
                />
                <div className={styles.subText02}>{t('afiliado.indicator_4_Number')}</div>
                <div className={styles.Text04Division02}>{t('afiliado.indicator_4_Description_1')}</div>
                <div className={styles.TextDefaultDivision02Center}>
                  {t('afiliado.indicator_4_Description_2')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.division03}>
        <div className="container px-5">
          <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03}`}
          >
            <span>{t('afiliado.title3')}</span>{t('afiliado.title3_1')}
            <br />{t('afiliado.title3_2')}
          </div>
          <div
            className={`text-center ${styles.title03} ${styles.deviceDivision03_01}`}
          >
            <span>{t('afiliado.title3')}</span>{t('afiliado.title3_1')}
            {t('afiliado.title3_2')}
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="masthead-device-mockup">
              <img
                src={Image08}
                style={{ width: "100%", height: "100%" }}
                alt="Imagem"
              />
            </div>
          </div>
          <div className={styles.subTitle03}>
            {t('afiliado.subtitle3')}
            <br />
            {t('afiliado.subtitle3_1')}
            <br />
            {t('afiliado.subtitle3_2')}
                      
          </div>

          <div
            className={`d-flex flex-lg-row align-items-center justify-content-center ${styles.deviceDivision03_01}`}
          >
            <div className="me-lg-3 mb-lg-0 m-2" onClick={handleHowItWorks}>
              <Button
                name={t('afiliado.button_como_funciona')}
                typeButton="secondary"
                color="black"
              />
            </div>
            <div className="m-2" onClick={handleRegist}>
              <Button name={t('afiliado.button_cadastre_agora')} typeButton="primary" />
            </div>
          </div>
        </div>
        <div className={`col-lg-12 ${styles.imageCourse2}`}>
          <div className="masthead-device-mockup">
            <img
              src={ImageCourse2}
              style={{ width: "100%", height: "100%" }}
              alt="Imagem"
            />
          </div>
        </div>
      </section>
      {/* ************************************************************************* */}

      <section className={styles.division04}>
        <div className={`container px-5`}>
          <div className={`row gx-5 align-items-start`}>
            <div className={`col-lg-6 ${styles.deviceDivision04}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image02}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-lg-0 text-center text-lg-start">
                <div className={styles.title04}>
                  {t('afiliado.title4')}
                </div>
                <div className={styles.subTitle04}>
                  {t('afiliado.subtitle4')}
                </div>
                <div
                  className={`d-flex flex-column flex-lg-row align-items-center ${styles.btnDivision01} ${styles.deviceDivision01}`}
                >
                  <div
                    className="me-lg-3 mb-4 mb-lg-0"
                    onClick={handleWhatWeDo}
                  >
                    <Button
                      name={t('afiliado.button_o_que_fazemos')}
                      typeButton="secondary"
                      color="black"
                    />
                  </div>
                  <div onClick={handleRegist}>
                    <Button name={t('afiliado.button_cadastre_agora')} typeButton="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-6 ${styles.deviceDivision04_01}`}>
              <div className="masthead-device-mockup">
                <img
                  src={Image02}
                  style={{ width: "100%", height: "100%" }}
                  alt="Imagem"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.division05}>
        <div className={`masthead ${styles.division05ColorTop}`}>
          <div className="container px-5">
            <div className={styles.title05}>
              {t('afiliado.title5')}
            </div>
            <div className={styles.subTitle05}>{t('afiliado.subtitle5')}</div>
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
              <div className="me-lg-3 mb-4 mb-lg-0" onClick={handleRegist}>
                <Button name={t('afiliado.button_cadastre_agora')} typeButton="primary" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
